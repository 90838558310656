import React, { useState, useEffect } from "react";
import {
  FaShoppingCart,
  FaSearch,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import { FiMenu, FiX } from "react-icons/fi";
import "./MobileViewHeader.css"; // Ensure this is the correct path to your CSS file
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const useRouteChange = (handler) => {
  const location = useLocation();
  useEffect(() => {
    handler();
    // This handler will be called every time the location changes.
  }, [location]);
};

const MobileViewHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useRouteChange(() => {
    setIsMenuOpen(false);
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const pageNavigation = (page) => {
    setIsMenuOpen(false); // Close the menu whenever navigating to a new page

    navigate(page);
  };
  return (
    <header className="mobile-view-header">
      <div
        onClick={() => pageNavigation("/")}
        className="mobile-view-header-logo"
      >
        maxsip <span>PURE</span>
      </div>
      <div className="mobile-view-header-icons">
        <FaShoppingCart
          onClick={() => pageNavigation("checkout")}
          className="mobile-view-icon"
        />
        <FaSearch className="mobile-view-icon" />
        <div onClick={toggleMenu}>
          {isMenuOpen ? (
            <FiX className="mobile-view-icon" />
          ) : (
            <FiMenu className="mobile-view-icon" />
          )}
        </div>
      </div>

      {isMenuOpen && (
        <nav className="mobile-view-navigation">
          <ul className="mobile-view-navigation-list">
            <li
              onClick={() => pageNavigation("our-products")}
              className="mobile-view-navigation-item"
            >
              Products
            </li>
            <li className="mobile-view-navigation-item">
            <a href="/support" style={{ textDecoration: 'none' }}>Support</a>
            </li>
            <li
              className="mobile-view-navigation-item apps-dropdown"
              onClick={toggleDropdown}
            >
              App Store {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/pure-plus">PURE +</Link>
                  </li>
                  <li>
                    <Link to="/build-app-store">Customize Store</Link>
                  </li>
                 
                </ul>
              )}
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default MobileViewHeader;
