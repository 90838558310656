import React, { useState } from "react";
import "./SupportPage.css";
import Footer from "../Footer/Footer";

const SupportPage = () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is the warranty and insurance policy?",
      answer: "Please check our full warranty and insurance policy:",
      link: "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/Warranty%20%26%20Insurance%20Policy.pdf?alt=media&token=c0559952-6932-4d60-b6d8-b03a60ee0240"
    },
    {
      question: "What is the return policy?",
      answer: "Please check our full return policy:.",
      link: "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/Return%20Policy.pdf?alt=media&token=a448303b-abd6-41ff-8d43-574db51f4ff1"
    },
    {
      question: "What is the shipping policy?",
      answer: "Please check our full shipping policy:",
      link: "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/Shipping%20Policy.pdf?alt=media&token=e585b83a-5643-4ab4-ac00-d0120bd42bcf"
    },
    {
      question: "Who is your Rabbinical Supervisor?",
      answer: "We’re under the strict supervision of Rabbi David Churba of Tech Flatbush (Machane Kadosh) to curate our list of permitted apps."
    },
  ];

  return (
    <div>
      <div className="support-container">
        <div className="faq-section">
          <h1>FAQs</h1>
          {faqData.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${openFaqIndex === index ? "open" : ""}`}
              onClick={() => toggleFaq(index)}
            >
              <div className="faq-question">{faq.question}</div>
              <div className="faq-answer">
                {faq.answer}
                {faq.link && (
                  <a href={faq.link} className="faq-link" target="_blank" rel="noopener noreferrer">
                    Click here learn more
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SupportPage;
