import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import backgroundImage from "../../../assets/bg-img-kosher.png";
import "./ChooseYourPlan.css";
import scrollUp from "../../../assets/scrollup.png";


function ChooseYourPlan(props) {
  const navigate = useNavigate();
  const [activeSlide, setactiveSlide] = useState(props.activeSlide);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const next = () =>
    activeSlide < props.data.length - 1 && setactiveSlide(activeSlide + 1);
  const prev = () => activeSlide > 0 && setactiveSlide(activeSlide - 1);
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll to top
    });
  }

  const handleMoreInfoClick = (path) => {
    switch(path) {
      case "Pure +":
        navigate("/pure-plus");
        break;
      case "Pure":
        navigate("/pure");
        break;
      case "Business":
        navigate("/business");
        break;
      case "Basic":
        navigate("/basic");
        break;
      case "Pure Light":
        navigate("/light");
        break;
      default:
        console.log("Path not found");
    }
  };
  
  const getStyles = (index) => {
    if (activeSlide === index)
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    else if (activeSlide - 1 === index)
      return {
        opacity: 1,
        transform: "translateX(-240px) translateZ(-400px) rotateY(35deg)",
        zIndex: 9,
      };
    else if (activeSlide + 1 === index)
      return {
        opacity: 1,
        transform: "translateX(240px) translateZ(-400px) rotateY(-35deg)",
        zIndex: 9,
      };
    else if (activeSlide - 2 === index)
      return {
        opacity: 1,
        transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
        zIndex: 8,
      };
    else if (activeSlide + 2 === index)
      return {
        opacity: 1,
        transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 8,
      };
    else if (index < activeSlide - 2)
      return {
        opacity: 0,
        transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
        zIndex: 7,
      };
    else if (index > activeSlide + 2)
      return {
        opacity: 0,
        transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 7,
      };
  };

  useEffect(() => {
    const checkScroll = () => {
      if (window.pageYOffset > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);


  
  return (
    <div className="background-image-container">
      <h1 className="choose-kosher-level-text">.</h1>

      <div className="slideC">
        {props.data.map((item, i) => (
          <React.Fragment key={item.id}>
            <div
              className="slide"
              style={{
                background: item.bgColor,
                boxShadow: `0 5px 20px ${item.bgColor}30`,
                ...getStyles(i),
              }}
            >
              <FontAwesomeIcon
                className="btn"
                onClick={prev}
                icon={faChevronLeft}
                color="#fff"
                size="2x"
              />
              <SliderContent {...item} handleMoreInfoClick={handleMoreInfoClick}/>{" "}
              <FontAwesomeIcon
                className="btn"
                onClick={next}
                icon={faChevronRight}
                color="#fff"
                size="2x"
              />
            </div>
            <div
              className="reflection"
              style={{
                background: `linear-gradient(to bottom, ${item.bgColor}40, transparent)`,
                ...getStyles(i),
              }}
            />
          </React.Fragment>
        ))}
        </div>
  
        {showScrollButton && (
          <button onClick={scrollToTop} className="scroll-to-top-btn">
            <img src={scrollUp} alt="Scroll to top" />
          </button>
        )}
      </div>
    
  );
}

const SliderContent = ({ title, subtitle, handleMoreInfoClick }) => {
  console.log("Check if SliderContent renders");
  return (
    <div className="sliderContent">
      <h2>{title}</h2>
      <p>{subtitle}</p>
      <button onClick={() => {

        console.log("Button clicked, title:", title);
        handleMoreInfoClick(title);
      }}>
        More Info
      </button>
    </div>
  );
};

export default ChooseYourPlan;