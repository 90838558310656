import React from "react";
import "./MobileViewLandingPage.css";
import mainTabImg from "../../../assets/main-tab-image.png";
import tabLogo from "../../../assets/pure-tab-with-logo.png";
import mobile from "../../../assets/pure-mobile-with-logo.png";
import backgroundImage from "../../../assets/banner-img.png";
import data from "../Choose_plan_page/data";
import ChoosePlanPageMobileView from "../ChoosePlanPageMobileView/ChoosePlanPageMobileView";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
import tab from "../../../assets/choose-your-phone.png";
import WhyChooseMaxsip from "./WhyChooseMaxsip/WhyChooseMaxsip";

const LandingPageMobileView = () => {
  const navigate = useNavigate();
  const handleBuyNowClick = () => {
    navigate("/our-products");
  };

  const handlePreorderClick = (deviceType) => {
    let path;
    switch (deviceType) {
      case "Smart Phone":
        path = "/mobile-selection";
        break;
      case "Tablet":
        path = "/tab-selection";
        break;
      default:
        path = "/our-products";
    }
    navigate(path);
  };

  return (
    <div className="landing-page-mobile-section">
      <UpperContainer handleBuyNowClick={handleBuyNowClick} />
      <div className="your-choice-pure-mobile">
        <div className="first-slide-inner-mobile">
          <h2>Your Choice</h2>
          <h2>Pure & Simple!</h2>

          <div className="buttons-container-mobile">
            <p>Kosher Approved</p>
            <p>Kosher App Store</p>
            <p>Browser Free</p>
          </div>
          <img
            src={tab}
            alt="Tablet with Kosher App"
            className="tablet-image"
          />
        </div>
      </div>

      <div className="promo-container">
        <div className="promo-background">
          {/* Background image will be set via CSS */}
        </div>
        <div className="promo-text">
          <h1>Maxsip PURE</h1>
          <p>
            Experience the pinnacle of mobile innovation with Maxsip Pure
            smartphones and tablets. Seamlessly blending style, functionality,
            and security, our products offer unparalleled performance and peace
            of mind in every interaction.
          </p>
        </div>
      </div>

      <div className="explore-devices-section">
        <br></br>
        <br></br>
        <h2>Explore Devices</h2>

        <div className="device-images">
          <div className="tablet-landing-page-mobile-view">
            <img src={tabLogo} alt="Tablet" className="tablet-img-mobile" />
            <p className="pricing-info">
              From $9.99 or{" "}
              <span style={{ textDecoration: "line-through", color: "red" }}>
                $350
              </span>{" "}
              $99.99
            </p>
            <a
              className="tab-link-mobile-view"
              onClick={() => handlePreorderClick("Tablet")}
            >
              Order Now
            </a>
            <ul className="device-features">
              <li>Customized OS</li>
              <li>Sim-Locked Devices</li>
              <li>Kosher App Store</li>
              <li>Color Options</li>
            </ul>
          </div>
          <div className="phone-landing-page-mobile-view">
            <img src={mobile} alt="Phone" className="phone-img" />
            <p className="pricing-info">
              From $9.99 or{" "}
              <span style={{ textDecoration: "line-through", color: "red" }}>
                $350
              </span>{" "}
              $99.99
            </p>
            <a
              className="tab-link-mobile-view"
              onClick={() => handlePreorderClick("Smart Phone")}
            >
              Order Now
            </a>
            <ul className="device-features">
              <li>Customized OS</li>
              <li>Sim-Locked Devices</li>
              <li>Kosher App Store</li>
              <li>Size Options</li>
            </ul>
          </div>
        </div>
      </div>

      <WhyChooseMaxsip />
      {/* <ChoosePlanPageMobileView data={data} activeSlide={2} /> */}

      <div className="footer-on-mobile-view">
        <Footer />
      </div>
    </div>
  );
};
const UpperContainer = ({ handleBuyNowClick }) => {
  return (
    <div
      className="upper-container-mobile"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="overlay-text">
        PURE connection in one touch
        <button onClick={handleBuyNowClick}>Buy Now</button>
      </div>
    </div>
  );
};

export default LandingPageMobileView;
