// TermsAndConditions.js
import React from "react";
import "./TermsAndConditions.css"; // Ensure you have a CSS file with this name

const TermsAndConditions = () => {
  return (
    <div className="terms-conditions">
      <h1 className="title-term-conditions">Maxsip Tel Terms and Conditions</h1>
      <h2>February 2024</h2>
      <p>
        THESE TERMS AND CONDITIONS OF SERVICE APPLY TO INTERNET ACCESS SERVICES
        PROVIDED BY MAXSIP TELECOM (“MAXSIP”).
      </p>

      <p>
        Maxsip provides broadband Internet access services and web-based
        application services (individually, the “Service”) subject to your
        compliance with the terms and conditions set forth in these “Terms and
        Conditions of Service” (the “Terms and Conditions”). Any terms or
        conditions included on any point-of-sale purchase receipt, recording of
        a non-written contract, Promotional Offer, Acceptable Use Policy, or
        Privacy Policy are incorporated into and made a part of these Terms and
        Conditions, which collectively form the Agreement between you and Maxsip
        (the “Agreement”). By accessing and using the Service, you represent and
        agree that you have read, understand, and consent to be bound by the
        Terms and Conditions. If you do not consent to be bound by the Terms and
        Conditions you must not use the Service or register as a subscriber of
        the Service and must promptly call the Maxsip Help Desk at (866)
        629-7471 to cancel the Service.
      </p>

      <p>
        Maxsip may revise the Agreement at any time by posting a revised version
        on Maxsip’s website at the following address:
        maxsipconnects.com/terms-and-conditions or by notifying you by email,
        conventional mail, telephone, or on your Maxsip portal. You are
        responsible for periodic review of the Agreement and must be aware of
        any such revisions. The modified terms will become effective three (3)
        days after posting to the website or, if we notify you by email,
        conventional mail, telephone, or in your portal, effective as stated in
        such message. By using the Service after any such modification, you
        agree to the latest version of the Terms and Conditions. However, any
        changes to provisions in the Resolution of Disputes section below will
        not apply to any disputes for which the parties have actual notice on or
        prior to the date the change takes effect.
      </p>

      <h2>ACCOUNT</h2>

      <p>
        Upon acceptance of your application for service and receipt of any
        applicable deposit(s), you will become a Subscriber to the Service
        (“Subscriber”). By registering as a Subscriber to the Service, you will
        receive an account (“Account”). You are solely and legally responsible
        for all activities conducted through your Account and any sub-accounts
        or usernames under your Account and agree that you will only use the
        Service for lawful purposes. You may permit another individual,
        including a minor, in your household to use your Account subject to your
        supervision, in which case you will retain all legal responsibility for
        the Account. Finally, you agree to provide accurate and complete
        registration information and to update this information when it changes.
        Failure to provide such accurate, complete, and updated information
        shall constitute a breach of this Agreement and unauthorized access to
        the Service and may result in immediate termination of your Account and
        subject you to civil and/or criminal liabilities.
      </p>

      <h2>SELECTION OF A USERNAME</h2>

      <p>
        You may not select or use the username of another person or a name
        subject to the rights of any person other than you. You also may not use
        a user name that suggests to other Subscribers the availability of
        Content that Maxsip, in its sole discretion, considers to be libelous,
        defamatory, invasive of privacy or publicity rights, threatening,
        abusive, illegal, obscene, lewd, lascivious, excessively violent,
        harassing, harmful, offensive, or otherwise objectionable or would
        constitute or encourage a criminal offense, violate the right of any
        party, or otherwise give rise to liability, violate any law or in
        violation of the Agreement. Furthermore, you agree that you will not
        attempt to conceal or disguise your username in any communications.
      </p>

      <h2>GENERAL USE OF SERVICES</h2>
      <p>
        You agree to use the Services in compliance with all applicable laws,
        including: (a) FCC regulations; and (b) the laws of the state, country,
        or region where you (i) reside, (ii) lawfully obtained the Service,
        (iii) download updates, and/or (iv) use the Service.
      </p>

      <h2>ACCEPTABLE USE</h2>
      <p>
        If Maxsip becomes aware, through Subscriber complaints or otherwise, of
        any Content that is available by you or generally made available through
        your Account or in a Public Area, and Maxsip, in its sole discretion,
        considers such Content to be libelous, defamatory, invasive of privacy
        or publicity rights, threatening, abusive, illegal, obscene, lewd,
        lascivious, excessively violent, harassing, harmful, offensive, or
        otherwise objectionable or would constitute or encourage a criminal
        offense, violate the right of any party, or otherwise give rise to
        liability, violate any law, or violate the Agreement, Maxsip shall have
        the right, but not the obligation, to immediately remove such Content
        and/ or to terminate your Account without notice. This policy applies to
        any Content made available by you or generally made available through
        your Account. As a Subscriber, you agree not to use the Service to:
        upload, post, email, transmit or otherwise make available any Content
        that is unlawful, harmful, threatening, abusive, harassing, tortuous,
        defamatory, vulgar, obscene, libelous, invasive of another’s privacy,
        hateful, or racially, ethnically or otherwise objectionable; harm minors
        in any way; impersonate any person or entity, including, but not limited
        to, a Maxsip official, forum leader, guide, or host, or falsely state or
        otherwise misrepresent your person or entity; forge headers or otherwise
        manipulate identifiers in order to disguise the origin of any Content
        transmitted through the Service; upload, post, email, transmit, or
        otherwise make available any Content that you do not have a right to
        make available under any law or under contractual or fiduciary
        relationships (such as inside information, proprietary and confidential
        information learned or disclosed as part of employment relationships or
        under nondisclosure agreements); upload, post, email, transmit, or
        otherwise make available any Content that infringes any patent,
        trademark, trade secret, copyright or other proprietary rights
        (“Rights”) of any party; upload, post, email, transmit, or otherwise
        make available any unsolicited or unauthorized advertising, promotional
        materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or
        any other form of solicitation, except in those areas that are
        designated for such purpose; upload, post, email, transmit, or otherwise
        make available any material that contains software viruses, or any other
        computer code, files, or programs designed to interrupt, destroy, or
        limit the functionality of any computer software or hardware or
        telecommunications equipment; disrupt the normal flow of dialogue, cause
        a screen to “scroll” faster than other users of the Service are able to
        type, or otherwise act in a manner that negatively affects other users’
        ability to engage in real time exchanges; interfere with, disrupt, or
        cause a disruption to the Service, servers, or networks connected to the
        Service, or disobey any requirements, procedures, policies, or
        regulations of networks connected to the Service; intentionally or
        unintentionally violate any applicable local, state, national or
        international law, including, but not limited to, regulations
        promulgated by the U.S. Securities and Exchange Commission, any rules of
        any national or other securities exchange, including, without
        limitation, the New York Stock Exchange, the American Stock Exchange or
        the NASDAQ, and any regulations having the force of law; “stalk” or
        otherwise harass another; NOR collect or store personal data about other
        users. Any violation of this provision will constitute a breach of the
        Agreement, and Maxsip reserves the right in its sole and absolute
        discretion to temporarily or permanently suspend or terminate your
        access to the Service without notice.
      </p>

      <p></p>

      <p>
        If any revision to the Agreement is unacceptable to you, you may
        terminate your subscription to the Service as provided in the
        Termination section below. Further, if you violate any of the terms and
        conditions contained within the Agreement, Maxsip may terminate and/or
        suspend your access to the Service without notice.
      </p>

      <h2>PROMOTIONS</h2>
      <p>
        Any sweepstakes, contests, raffles, surveys, games, loyalty programs,
        rewards, or similar promotions (collectively, “Promotions”) made
        available through the Service or Maxsip App may be governed by rules
        that are separate from this Agreement. If you participate in any
        Promotions, please review the applicable rules as well as the Privacy
        Policy. If the rules for a Promotion conflict with this Agreement, the
        Promotion rules will govern.{" "}
      </p>

      <h1>CONTENT</h1>
      <h2>Subscriber Content</h2>
      <p>
        You are solely responsible for all information, technology, products,
        processes, data, communications, software, photos, video, graphics,
        music, sounds, and other material and services (collectively referred to
        as “Content”) that you transmit through your Account and/or make
        available on or through your web site.
      </p>

      <h2>Submission of Content to Public Areas</h2>
      <p>
        “Public Area” means chat areas, public message boards, newsgroups or
        email functions offered as part of the Service. By submitting or
        uploading Content to any Public Area, you automatically grant to and/or
        warrant that the owner of such Content has expressly granted to Maxsip a
        royalty-free, perpetual, irrevocable, non-exclusive, unrestricted right
        and license to use, reproduce, modify, adapt, publish, translate, create
        derivative works from, distribute, perform and display such Content (in
        whole or part) worldwide and/or to incorporate it in other works in any
        form, media or technology now known or later developed for the full term
        of any Rights that may exist in such Content.
      </p>

      <h2>Compliance with Digital Millennium Copyright Act</h2>
      <p>
        You agree to fully comply with copyright law and all provisions of the
        Digital Millennium Copyright Act of 1998 (the “DMCA”) and to fully
        cooperate with Maxsip in its efforts to comply with the DMCA. Maxsip may
        investigate any reported violation of its policies or complaints of
        infringement relating to your use of the Service and take any action
        that it deems appropriate. Such action may include, but is not limited
        to, issuing warnings, and suspension or termination of your Account and
        access to the Service. In the event that you believe that any third
        party has infringed on any copyrighted material of yours, you shall
        provide Maxsip with notice in compliance with the provisions of the
        DMCA, through Maxsip’s DMCA Designated Agent, Madison Kucker, at
        legal@maxsiptel.com, or by phone at (516) 866-7300. Furthermore, you
        agree that Maxsip shall have no liability until such time as such notice
        has been actually received. If Maxsip receives a valid notice that your
        use constitutes alleged infringement, Maxsip will take action consistent
        with the requirements of the DCMA, including but not limited to
        terminating your Account and access to the Service.
      </p>
      <h1>Service Content</h1>
      <h2>Accessing Content. You acknowledge that:</h2>
      <p>
        The Service permits access to Content that is or may be protected by
        copyrights, trademarks, patents or other proprietary rights (including
        intellectual property) (“Rights”) owned by Maxsip and other third
        parties; These Rights are valid and protected in all media existing now
        or later developed; and Except as is explicitly provided otherwise,
        applicable copyright and other intellectual property laws shall govern
        your use of Content; provided, however, that such use does not grant you
        a license under any of the Rights in the Content described herein. All
        such Rights are retained by Maxsip and its affiliates and/or any
        third-party owner of such Rights. Further, you agree that you may upload
        software files, message boards or otherwise transmit only Content that
        is not encrypted; Content that is not subject to any Rights; and/or
        Content for which any holder of Rights has given express authorization
        for its distribution.
      </p>

      <h2>Intellectual Property Rights in Content</h2>
      <p>
        You understand that rights in any Content, including any compilations on
        the Service, that is copyrighted by a third party, is claimed and
        reserved by Maxsip and may not be copied, used, uploaded, posted,
        transmitted, distributed or reproduced in any way without the express
        written consent of Maxsip. If you wish to obtain rights to copy any
        Content on the Web Site, please email your request legal@maxsiptel.com.
      </p>

      <h2>Use of Trademarks or Service Marks</h2>
      <p>
        The Maxsip names and logos and all related product and service names,
        design marks and slogans (the “Marks”) are the trademarks or service
        marks of Maxsip and its affiliate companies. You are not authorized to
        use any of the Marks in any advertising, publicity, or in any other
        commercial manner without the prior written consent of Maxsip. If you
        wish to obtain such authorization, please email your request
        legal@maxsiptel.com.
      </p>

      <h2>TERMINATION AND CANCELLATION POLICY</h2>
      <p>
        Maxsip does not anticipate that you will fail to pay for the Service(s)
        on a timely basis, and we do not extend credit to customers. Any fees,
        charges, and assessments due to late payment or nonpayment are
        liquidated damages intended to be a reasonable advance estimate of our
        costs resulting from late payments and non-payments. These costs will be
        difficult to calculate or to predict when we set such fees, charges, and
        assessments, because we cannot know in advance: (a) whether you will pay
        for the Service(s) on a timely basis, if ever; (b) if you do pay late,
        when you will actually pay; and (c) what costs we will incur because of
        your late payment or non-payment. Cancellation Billing Policy: The
        monthly charge for Internet service will not be prorated for the final
        partial month of service. The full monthly service fees will apply, even
        if your service is active for only a partial month. Maxsip will not
        disconnect all or part of a Subscriber’s internet service for nonpayment
        until the bill is at least fourteen (14) days past due. This termination
        and cancellation provision is not applicable to the Affordable
        Connectivity Program (“ACP”) or Lifeline plans where the federal
        government provides a benefit for a portion of the broadband services.
        Any disconnection, termination, or cancellation of ACP or Lifeline
        services is governed by the applicable FCC rules.{" "}
      </p>

      <h2>Prohibition on Customer Access to Software and/or Facilities</h2>
      <p>
        You agree that you will not access or attempt to access software
        (including reverse engineering, decompiling, or disassembling the
        software or attempting in any manner to recreate the source code or
        object codes) or facilities (including remote computing systems)
        furnished in connection with this Agreement. Any attempt by you to
        access and/or subvert any network security measures of Maxsip or any
        other network shall entitle Maxsip to immediately terminate your access
        to the Service without notice.
      </p>

      <h2>Maxsip Application and Software</h2>
      <p>
        For support purposes, Maxsip may elect to install software (“Maxsip
        App”) or request the installation of the Maxsip web-based application on
        your phone, tablet or on your computer to facilitate troubleshooting and
        the distribution and maintenance of desktop support and provide
        notifications and information about the Service.
      </p>

      <h2>Intellectual Property Rights.</h2>
      <p>
        {" "}
        All copyrights, trademarks, patents, and/or other proprietary rights
        (“Intellectual Property Rights”) in the Maxsip App (including but not
        limited to any images, photographs, animations, video, audio, music,
        text, and “applets” incorporated into the Maxsip App), the accompanying
        printed materials, and any copies of the Maxsip App are owned by Maxsip.
        All title and intellectual property rights in and to the content that
        may be accessed through use of the Maxsip App is the property of Maxsip
        or other third parties and may be protected by applicable copyright or
        other intellectual property laws and treaties. You may not copy the
        printed materials accompanying the Maxsip App. You must respect the
        intellectual property rights of others while using the Services. Third
        Party Software. As described above, components of the Maxsip App and
        other maintenance applications may incorporate third party software, and
        your installation of the software and use of the Service indicates your
        acceptance of the license agreement(s) of each.
      </p>

      <h2>Networking</h2>
      <p>
        Networks or Equipment not supplied or installed by Maxsip The Maxsip
        Help desk does not advise or assist with networks or equipment related
        to networks not supplied or installed by Maxsip. Customer inquiries
        regarding networking should be directed to third party computer
        technicians and network equipment manufacturers. Networks and Equipment
        supplied or installed by Maxsip The Maxsip Help desk offers technical
        support for networks and equipment supplied or installed by Maxsip.
        Subscribers can reach the Maxsip Help Desk by calling (866) 629-7471 or
        via the web at: support@maxsiptel.com. Maxsip networks and equipment are
        subject to the specific terms and conditions of the relevant
        contract(s), terms of service, and/or other customer agreement
        established and agreed to at the time of purchase and/or installation of
        each. During troubleshooting sessions where networked computers are
        present, the Maxsip Help desk technician will ask the customer to
        disconnect their networked equipment from the Equipment and directly
        connect the hardware to one Windows-based or Apple computer. Doing so
        will help the technician diagnose issues with the Equipment at the
        customer’s premises. Failure to comply with the Maxsip technician’s
        request in this matter will release Maxsip from responsibility for
        further diagnosis, and customer hereby expressly assumes all liability
        and risk of loss in exchange for the assistance from the Help desk.
      </p>

      <h2>Service Speed</h2>
      <p>
        Maxsip services may come in a variety of speeds. The availability of
        some service speeds may depend on the location of facilities in your
        neighborhood and on your street, the mobile device used by the
        Subscriber, network capacity, terrain, weather, if you are on a private
        or public wi-fi network and the signal strength available. Devices also
        have varying speed capabilities and may connect to different networks
        depending on technology. Even within coverage areas and with
        broadband-capable devices, network changes, traffic volume, outages,
        technical limitations, signal strength, obstructions, weather, public
        safety needs, and other conditions may impact speeds and service
        availability. Not all service speeds are available in all areas. Maxsip
        does not guarantee that the Service, Equipment, or other equipment
        authorized by Maxsip for use in connection with the Service will perform
        at a particular speed, bandwidth, or data throughput rate, or will be
        uninterrupted, error-free, secure, or free of viruses, worms, disabling
        code or conditions, or the like.
      </p>

      <h2>Fair Usage Policy</h2>
      <p>
        At Maxsip, we are committed to providing fair and equitable access to
        our services for all subscribers. Our Fair Usage Policy ensures that our
        network remains reliable and efficient for everyone. By using our
        services, you agree to abide by the following guidelines: Unlimited
        Usage (Applies to “Unlimited Plans” Only): Data usage is provided on an
        unlimited basis. However, excessive usage beyond what is considered
        reasonable for individual and non-commercial purposes may impact network
        performance. Subscribers enjoy unlimited talk time for up to 3000
        minutes per month. Beyond this threshold, standard rates may apply or
        the service may be subject to fair usage policies to prevent abuse and
        ensure equitable access for all customers. Text messaging is provided on
        an unlimited basis for personal use only. This service is not intended
        for commercial purposes, such as bulk messaging or spamming. Network
        Speed Optimization: In accordance with standard industry practices and
        fair use, Maxsip reserves the right to optimize network speeds for
        subscribers who exceed predefined usage thresholds. This measure helps
        to maintain optimal network performance for all users and prevent abuse
        of the service. Network speed optimization may be applied temporarily
        until the start of the next billing cycle or until usage returns to
        within acceptable limits. Enforcement: Maxsip reserves the right to
        monitor network usage and take appropriate action against any misuse or
        abuse of our services. Enforcement actions may include, but are not
        limited to, throttling data speeds, suspending or terminating service,
        or applying additional charges as outlined in our terms and conditions.
        Review and Amendments: We regularly review our Fair Usage Policy to
        ensure it aligns with industry standards and best serves the interests
        of our customers. Maxsip reserves the right to amend this policy at any
        time with prior notice to subscribers. By using Maxsip Tel’s services,
        you agree to adhere to this Fair Usage Policy as outlined herein.
      </p>

      <h2>Disclaimer of Warranties; Limitation of Liability</h2>
      <p>
        The following is capitalized to emphasize its importance. YOU EXPRESSLY
        AGREE BY YOUR USE OF THE SERVICE THAT YOUR USE OF THE SERVICE, ITS
        CONTENT, AND RELATED SERVICES AVAILABLE ON OR LINKED TO THE MAXSIP WEB
        SITE ARE PROVIDED TO YOU “AS IS” AND WITHOUT WARRANTIES OF ANY KIND,
        EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
        NON-INFRINGEMENT. MAXSIP DOES NOT WARRANT THAT ANY OF THE SERVICE,
        EQUIPMENT, OR OTHER EQUIPMENT AUTHORIZED BY MAXSIP FOR USE IN CONNECTION
        WITH THE SERVICE WILL PERFORM AT A PARTICULAR SPEED, BANDWIDTH OR DATA
        THROUGHPUT RATE, OR WILL BE UNINTERRUPTED, ERROR-FREE, SECURE, OR FREE
        OF VIRUSES, WORMS, DISABLING CODE OR CONDITIONS, OR THE LIKE. MAXSIP
        SHALL NOT BE LIABLE FOR LOSS OF YOUR DATA, OR IF CHANGES IN OPERATION,
        PROCEDURES, OR SERVICES REQUIRE MODIFICATION OR ALTERATION OF YOUR
        EQUIPMENT (INCLUDING ANY OTHER EQUIPMENT AUTHORIZED BY MAXSIP FOR USE IN
        CONNECTION WITH THE SERVICE), RENDER THE EQUIPMENT OBSOLETE OR OTHERWISE
        AFFECT ITS PERFORMANCE. THE EQUIPMENT IS PROVIDED ON AN “AS IS” BASIS
        WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING THE
        IMPLIED WARRANTY THAT THE EQUIPMENT IS REASONABLY FIT FOR THE PURPOSE
        FOR WHICH IT IS TO BE USED AND THE IMPLIED WARRANTIES OF MERCHANTABILITY
        AND FITNESS FOR A PARTICULAR PURPOSE, UNLESS SUCH WARRANTIES ARE LEGALLY
        INCAPABLE OF EXCLUSION. Maxsip is not responsible and shall not be
        liable for the listings or the advertisements contained on the website,
        and Maxsip does not: Guarantee the accuracy, completeness, usefulness or
        adequacy of any other web sites, services, goods or advertisements that
        may be linked to the website; Make any endorsement, express or implied,
        of any other web sites, services, goods or advertisements that may be
        linked to the website; Check for licenses with respect to licensed
        professions or trades prior to publishing advertisements and does not
        assume the responsibility for monitoring the use of trademarks,
        certifications, or other rights of third parties; NOR Warrant that the
        Service will be uninterrupted or error-free or that defects in the
        Service will be corrected. The Service and any Equipment or software
        made available are provided on an “as is” basis. IN NO EVENT SHALL
        MAXSIP NOR ANY OF ITS AFFILIATES, AGENTS LICENSORS, SUPPLIERS OR
        CONTRACTORS BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING SPECIAL,
        INDIRECT, CONSEQUENTIAL, OR INCIDENTAL DAMAGES FOR: (I) IMPAIRMENT,
        INABILITY TO USE OR LOSS, INTERRUPTION OR DELAY OF THE SERVICE; (II)
        BUSINESS INTERRUPTION, OR LOSS OF PROFITS, REVENUE, USE OR DATA, WHETHER
        BROUGHT IN CONTRACT OR TORT, ARISING OUT OF OR CONNECTED WITH THE
        SERVICE, WEB SITE, OR THE USE, RELIANCE UPON, OR PERFORMANCE OF ANY
        MATERIAL CONTAINED IN OR ACCESSED FROM THE WEB SITE, EVEN IF MAXSIP OR
        ITS AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES; AND/OR (III) ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS,
        OR OTHER INACCURACIES IN THE SERVICE AND/OR MATERIALS OR INFORMATION
        DOWNLOADED THROUGH OR HYPERLINKED FROM THE SERVICE. IN NO EVENT
        WHATSOEVER WILL MAXSIP BE LIABLE FOR ANY DAMAGES TO THE EQUIPMENT,
        INCLUDING SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES,
        INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, REVENUE, USE OR DATA, EVEN
        IF MAXSIP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FOR
        PURPOSES OF THIS SECTION, “MAXSIP” IS DEEMED TO INCLUDE MAXSIP, ITS
        PARENT COMPANY AND THE RESPECTIVE SUBSIDIARIES AND AFFILIATES OF EACH,
        AND THE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, REPRESENTATIVES,
        SUBCONTRACTORS AND SUPPLIERS OF EACH OF THEM. By using the Service you
        are agreeing to these terms. This limitation of liability and these
        disclaimers of warranties allocate the risks of the Agreement between us
        and you. Maxsip relies on this allocation, which is reflected in the
        pricing of the Services, and is an essential element of the basis of the
        bargain between us. Indemnification You agree to defend, indemnify, and
        hold harmless Maxsip, its affiliated companies and their respective
        directors, officers, employees and agents, service providers, suppliers
        and contractors from all liabilities, actions, suits, proceedings,
        claims, damages and expenses, including without limitation, reasonable
        attorney’s fees, arising from or relating to your access to or use of
        the Service, the website and your Account or processing of your data or
        information in accordance with these Terms. Maxsip reserves the right,
        at its own expense, to assume the exclusive defense and control of any
        matter otherwise subject to indemnification by you hereunder, and in
        such event, you shall have no further obligation to provide
        indemnification for such matter. Resolution of Disputes Please read this
        section carefully. It affects rights that you may otherwise have. It
        provides for resolution of most disputes through arbitration instead of
        court trials and class actions. Arbitration is final and binding and
        subject to only very limited review by a court. This arbitration clause
        shall survive termination of this agreement.
      </p>

      <h2>Binding Arbitration</h2>
      <p>
        This provision is intended to be interpreted broadly to encompass all
        disputes or claims arising out of our relationship. Any dispute or
        claim, including those against any of our subsidiary, parent, or
        affiliate companies, arising out of or relating to this Agreement, our
        Privacy Policy, our Acceptable Use Policy, or the Service or any
        equipment used in connection with the Service (whether based in
        contract, tort, statute, fraud , misrepresentation or any other legal
        theory) will be resolved by binding arbitration except that (1) you may
        take claims to small claims court if they qualify for hearing by such a
        court, or (2) you or we may choose to pursue claims in court if the
        claims relate solely to the collection of any debts you owe to us.
      </p>

      <h2>Arbitration Procedures</h2>
      <p>
        You must first present any claim or dispute to us by contacting Customer
        Care to allow us an opportunity to resolve the dispute. You may request
        arbitration if your claim or dispute cannot be resolved within 60 days.
        The arbitration of any dispute or claim shall be resolved by binding
        arbitration administered by the JAMS under its rules and procedures in
        effect when the claim is filed. The rules and procedures and other
        information, including information on fees, may be obtained from JAMS’
        website (www.jamsadr.com) or by calling JAMS at 949-2241810. You and we
        agree that this Agreement evidences a transaction in interstate commerce
        and this arbitration provision will be interpreted and enforced in
        accordance with the Federal Arbitration Act and federal arbitration law.
        Unless you and we agree otherwise, any arbitration will take place in
        the county seat for the county in which your service address is located.
        At either party’s election, the arbitration shall be held
        telephonically. As set forth below, you and we both agree that any
        arbitration will be solely between you and Maxsip, not as part of a
        consolidated, class-wide, or representative claim (that is, not brought
        on behalf of or together with another individual’s claim). An arbitrator
        may award any relief or damages (including injunctive or declaratory
        relief) that a court could award, except an arbitrator may not award
        relief in excess of or contrary to what this Agreement provides and may
        not order relief on a consolidated, class-wide, or representative basis.
        Except as set forth in the Waiver of Class Actions section below, if any
        portion of this arbitration clause is determined to be inapplicable or
        invalid by a court, arbitrator, or state or federal agency with proper
        jurisdiction, then the remainder shall still be given full force and
        effect. Judgment on any arbitration award may be entered in any court
        having proper jurisdiction. Both you and we agree that all issues
        relating to the validity of this agreement to arbitrate will be decided
        by a court (including but not limited to the substantive scope of our
        agreement to arbitrate or whether consolidated, class-wide, or
        representative arbitration is allowed), and do not intend to confer any
        such powers on an arbitrator.
      </p>

      <h2>Costs of Arbitration</h2>
      <p>
        For claims of less than $1,000, you will be obligated to pay $25 and we
        will pay all other administrative costs and fees. For claims over $1,000
        but under $75,000, you will be obligated to pay your share of the
        arbitration fees, but no more than the equivalent court filing fee for a
        court action filed in the jurisdiction where your billing address is
        located. For arbitrations in excess of $75,000, all administrative fees
        and expenses of arbitration will be divided equally between you and us.
        In all arbitrations, each party will bear the expense of its own
        counsel, experts, witnesses and preparation and presentation of evidence
        at the arbitration.
      </p>

      <h2>Waiver of Class Actions</h2>
      <p>
        By this Agreement, both you and we are waiving certain rights to
        litigate disputes in court. You and we both agree that any arbitration
        will be conducted on an individual basis only and not on a consolidated,
        class-wide, or representative basis or the like. If for any reason any
        court, arbitrator, or state or federal agency with proper jurisdiction
        holds that this restriction is unconscionable or otherwise
        unenforceable, then we both agree: (i) that this agreement to arbitrate
        will be invalidated and terminated and the dispute must be brought in
        court; and (ii) you and we both waive, to the fullest extent allowed by
        law, any right to pursue any claims on a class or consolidated basis or
        in a representative capacity. In the event that this arbitration
        agreement is invalidated and terminated in accordance with this section,
        both you and we agree to submit to the jurisdiction of the United States
        District Court for the Southern District of New York.
      </p>

      <h2>Limitations Period</h2>
      <p>
        Any arbitration or legal action with respect to any and all claims or
        causes of action related to or arising out of this Agreement must be
        brought within two years after the cause of action arises, or within the
        applicable statutory period of time, whichever is shorter. This
        limitations period does not apply to any given cause of action when the
        statutory limitations period for that cause of action cannot be waived,
        restricted, or otherwise limited by you.
      </p>

      <h2>Monthly Billing; “Past Due Balance”</h2>
      <p>
        You agree to timely pay all monthly bills. Any amount outstanding after
        the due date on the Customer bill shall be deemed a “past due balance”.
        In the event of a disagreement about a Customer bill, please contact
        Maxsip Customer Care at (866) 629-7471.
      </p>

      <h2>Right of Access</h2>
      <p>
        By using the Service, you grant permission for Maxsip and its agents and
        employees to enter upon the property of the Subscriber for the purpose
        of installation, inspection, maintenance, testing, and repair of the
        Service to the Subscriber’s premises and, upon service being cancelled
        for any reason, the Subscriber grants permission for Maxsip, during
        reasonable hours, to enter upon the premises and remove all equipment
        and material belonging to Maxsip and to discontinue service thereto.
      </p>

      <h2>Termination</h2>
      <p>
        Either you or Maxsip may terminate your Account at any time and for any
        reason whatsoever. Your only right with respect to any dissatisfaction
        with the Maxsip App or Service is to terminate your Account by
        delivering notice to Maxsip. Any such notice of termination will be
        effective upon its actual receipt by Maxsip. If this limitation is ruled
        inapplicable or invalid by a court, arbitrator, or state or federal
        agency with proper jurisdiction, then the remainder of the Agreement
        shall still be given full force and effect. This termination provision
        is not applicable to the ACP or Lifeline plans where the federal
        government provides a benefit for a portion (or all) of the broadband
        services. Any such terminations of ACP or Lifeline Subscribers shall
        comply with the applicable FCC regulations. ACP and Lifeline customers
        may also submit complaints or disputes directly to the FCC at
        consumercomplaints.fcc.gov.
      </p>

      <h2>Waiver</h2>
      <p>
        No waiver by either party of any breach by the other party of any of its
        covenants, obligations, or agreements under this Agreement and no waiver
        by either party of any term or condition of this Agreement shall be a
        waiver of any subsequent breach or failure or of any other covenant,
        obligation, agreement, term or condition, nor shall any forbearance to
        seek a remedy for any breach or failure be a waiver of any rights and
        remedies with respect to such or any subsequent breach or failure.
      </p>

      <h2>Miscellaneous</h2>
      <p>
        If any provision of these terms shall be unlawful, void, or for any
        reason unenforceable, then that provision shall be deemed severable from
        these terms and shall not affect the validity and enforceability of any
        remaining provisions.
      </p>

      <h2>Governing Law</h2>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of New York, without giving effect to any principles
        or conflicts of law.
      </p>
    </div>
  );
};

export default TermsAndConditions;
