import React, { useState, useEffect, useRef } from "react";
import "./KosherImageSlider.css";
import { top_images, middle_images, bottom_images, additional_images, initial_img } from './images';

const KosherImageSlider = () => {
  const [currentImage, setCurrentImage] = useState(initial_img);
  const [animationClass, setAnimationClass] = useState("fade-in");
  const [lastCollisionIndex, setLastCollisionIndex] = useState(null);
  const [collisionFlag, setCollisionFlag] = useState(false);
  const [initialDelayOver, setInitialDelayOver] = useState(false); // New state to track initial delay
  const lastCollisionTimes = useRef({}); // Use ref to track the last collision times

  useEffect(() => {
    const initialTimer = setTimeout(() => {
      setInitialDelayOver(true);
    }, 3000); // 5 seconds initial delay

    return () => clearTimeout(initialTimer);
  }, []);

  useEffect(() => {
    if (!initialDelayOver) return; // Skip the effect if the initial delay is not over

    const updateCurrentImage = () => {
      const centerMobile = document.querySelector(".center-mobile");
      const middleRow = document.querySelector(".middle-row");
      const images = middleRow.querySelectorAll(".slider-image");

      images.forEach((image, index) => {
        const rect = image.getBoundingClientRect();
        const mobileRect = centerMobile.getBoundingClientRect();

        const now = Date.now();
        const lastCollisionTime = lastCollisionTimes.current[index] || 0;
        const timeSinceLastCollision = now - lastCollisionTime;

        if (
          rect.left < mobileRect.right &&
          rect.right > mobileRect.left &&
          rect.top < mobileRect.bottom &&
          rect.bottom > mobileRect.top &&
          index !== lastCollisionIndex &&
          timeSinceLastCollision > 20000 // 15 seconds in milliseconds
        ) {
          if (!collisionFlag) {
            setCollisionFlag(true);
            setLastCollisionIndex(index);
            lastCollisionTimes.current[index] = now; // Update the last collision time

            setAnimationClass("fade-out"); // Start fade-out animation
            setTimeout(() => {
              setCurrentImage(
                additional_images[index % additional_images.length]
              );
              setAnimationClass("fade-in"); // Start fade-in animation for new image
              setTimeout(() => {
                setCollisionFlag(false); // Allow new collision detection
              }, 2900); // Duration of fade-in animation
            }, 1500); // Duration of fade-out animation
          }
        }
      });
    };

    const intervalId = setInterval(updateCurrentImage, 100);

    return () => clearInterval(intervalId);
  }, [lastCollisionIndex, collisionFlag, initialDelayOver]);

  return (
    <div className="image-slider">
   

   <div className="stacked-div">Create Your Own App Store</div>
      <div className="row top-row">
        
        {[...top_images, ...top_images, ...top_images].map((image, index) => (
          <img
            src={image}
            alt={`Slide ${index}`}
            key={index}
            className="slider-image top-image"
          />
        ))}
      </div>
      <div className="row middle-row">
        {[...middle_images, ...middle_images, ...middle_images].map((image, index) => (
          <img
            src={image}
            alt={`Slide ${index}`}
            key={index}
            className="slider-image middle-image"
          />
        ))}
      </div>
      <div className="row bottom-row">
        {[...bottom_images, ...bottom_images, ...bottom_images].map((image, index) => (
          <img
            src={image}
            alt={`Slide ${index}`}
            key={index}
            className="slider-image bottom-image"
          />
        ))}
      </div>
      <div className="center-mobile">
        <img src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/static-images%2FdoM8R3C%20-%20Imgur.png?alt=media&token=96eee144-f912-4371-a527-b06cc9b8a46e" alt="Mobile" />
        <div className="mobile-overlay">
          <div className="app-bar">
            <span className="app-bar-icon">📶🔋</span>
            <span>10:25 pm</span>
          </div>
          <div className="content">
            <div className={`album-art ${animationClass}`}>
              <img src={currentImage} alt="Album Art" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KosherImageSlider;
