// DeviceSelectionCard.jsx
import "./DeviceSelectionCard.css"; // Make sure the path to your CSS file is correct
import React from "react";
import { useNavigate } from "react-router-dom";

const DeviceSelectionCard = ({
  deviceType,
  price,
  features,
  imageUrl,
  colorOptions,
}) => {
  // A simple state to hold the selected color
  const [selectedColor, setSelectedColor] = React.useState(colorOptions[0]);

  const navigate = useNavigate(); // Hook to navigate

  const handlePreorderClick = () => {
    // Define the base path or logic to determine the path based on the deviceType
    let path;
    switch (deviceType) {
      case "Smart Phone":
        path = "/mobile-selection";
        break;
      case "Tablet":
        path = "/tab-selection";
        break;
      // Add more cases as needed for different device types
      default:
        path = "/our-products";
    }

    navigate(path); // Navigate to the corresponding path
  };

  return (
    <div className="device-selection-card-page">
      <div
        className={
          deviceType === "Smart Phone"
            ? "left-device-selection-card"
            : "device-selection-card"
        }
      >
        <h3 className="device-type">{deviceType}</h3>
        <p className="device-price">
          <span style={{ textDecoration: "line-through", color: "red" }}>
            $350
          </span>{" "}
          {price}
        </p>{" "}
        <div className="color-selection">
          {colorOptions.map((color) => (
            <button
              key={color}
              className={`color-option ${
                selectedColor === color ? "selected" : ""
              }`}
              style={{ backgroundColor: color }}
              onClick={() => setSelectedColor(color)}
              aria-label={`Select ${color}`}
            />
          ))}
        </div>
        <button
          className={
            deviceType === "Smart Phone"
              ? "left-preorder-button"
              : "preorder-button"
          }
          onClick={handlePreorderClick}
        >
          Order Now
        </button>
        <ul
          className={
            deviceType === "Smart Phone" ? "left-feature-list" : "feature-list"
          }
        >
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <div
          className={
            deviceType === "Tablet" ? "tab-image-scroll" : "device-image-scroll"
          }
        >
          <img src={imageUrl} alt={deviceType} />
        </div>
      </div>
    </div>
  );
};

export default DeviceSelectionCard;
