import React from 'react';
import basic from '../../assets/basic.png'; 
import scrollUp from "../../assets/scrollup.png";
import {isMobile} from 'react-device-detect';



const BasicSection = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll to top
    });
  }
  
  
  return (
    <div><div className={isMobile ? "app-selection-mobile" : "app-selection"}>
    <h1>Basic</h1>
  <div>
    <img src={basic} alt="" />
  </div>    
  </div>
  <button onClick={scrollToTop} className="scroll-to-top-btn">
  <img src={scrollUp} alt="Scroll to top" />
</button> 
</div>
    
  );
};

export default BasicSection;
