import React, { useEffect, useRef } from 'react';
import './ImageSpreader.css';

const ImageSpreader = () => {
  const iconRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          iconRef.current.classList.add('animate');
          const icons = iconRef.current.querySelectorAll('.icon');
          icons.forEach((icon) => {
            icon.classList.add('animate');
          });
        } else {
          iconRef.current.classList.remove('animate'); 
          const icons = iconRef.current.querySelectorAll('.icon');
          icons.forEach((icon) => {
            icon.classList.remove('animate');
          });
          void iconRef.current.offsetWidth; // Reset the animation by forcing reflow
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    if (iconRef.current) {
      observer.observe(iconRef.current);
    }

    return () => {
      if (iconRef.current) {
        observer.unobserve(iconRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div className="box" ref={iconRef}>
        <div className="icon img1">
          <img src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2FMax%20music_with%20apps-med-min.png?alt=media&token=6227d429-d5f7-4c78-a502-68cf729a3d76" alt="Image 1" className="doc-image" />
        </div>
        <div className="icon img2">
          <img src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2Foutput-onlinepngtools.png?alt=media&token=63ea2af3-cacd-47a1-a9ea-7315b508037a" alt="Image 2" className="doc-image" />
        </div>
        {/* <div className="icon img3">
          <img src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fdevices-all%2Fdialin%20phone-kosher-logo%202.png?alt=media&token=6f17b310-7aff-4bf1-a889-c02674fa53ba" alt="Image 3" className="doc-image" />
        </div> */}
        <div className="icon img4">
          <img src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fdevices-all%2Fpure-tab-with-logo.png?alt=media&token=63264ecf-abe7-4c80-974c-0ff22f58ee81" alt="Image 4" className="doc-image" />
        </div>
      </div>
    </div>
  );
};

export default ImageSpreader;
