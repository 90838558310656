import axios from "axios";

const authFunction = async () => {
  const response = await axios.post("/authenticate", {
    vendor_id: "MaxSip",
    username: "MaxSipUser",
    password: "MaxSipbtK123hyutrw22",
    pin: "987659876522",
    agent_id: "ewebsiteapi",
    source: "WEBSITE",
  });

  return response;
};

const checkServices = async (token, details) => {
  const response = await axios.post("/checkServices", {
    token: token,
    zipCode: details.zipCode,
  });
  return response;
};

const getPlanList = async (token, details) => {
  const response = await axios.post("/getPlanList", {
    token: token,
    zipCode: details.zipCode,
  });
  return response;
};

const paymentDetails = async (token, details) => {
  const response = await axios.post("/paymentDetails", {
    token: token,
    details: details,
  });
  return response;
};


const makePayment = async (token, details) => {
  const response = await axios.post("/makePayment", {
    token: token,
    details: details,
  });
  return response;
};




const captureCard = async (details) => {
  const response = await axios.post("/captureCard", {
    details: details,
  });
  return response;
};

const captureCardForKosherDevice = async (details) => {
  const response = await axios.post("/captureCardForKosherDevice", {
    details: details,
  });
  return response;
};


const addNonLifeline = async (token, details) => {
  const response = await axios.post("/addNonLifeline", {
    token: token,
    details: details,
  });
  return response;
};

const addCard = async (token, details) => {
  const response = await axios.post("/addCard", {
    token: token,
    details: details,
  });
  return response;
};

const autoPay = async (token, details) => {
  const response = await axios.post("/autoPay", {
    token: token,
    details: details,
  });
  return response;
};


const captureCardForFinance = async (details) => {
  const response = await axios.post("/captureCardForFinance", {
    details: details,
  });
  return response;
};


const insertRetailUser = async (details) => {
  const response = await axios.post("/insertRetailUser", {
    details: details,
  });
  return response;
};


const saveToHubSpot = async (details) => {
  const response = await axios.post("/saveToHubSpot", {
    details: details,
  });
  return response;
};


const getSalesTaxPercent = async (details) => {
  const response = await axios.post("/getSalesTaxPercent", {
    details: details,
  });
  return response.data;
};





export default {
  authFunction,
  checkServices,
  paymentDetails,
  captureCard,
  makePayment,
  addNonLifeline,
  addCard,
  getPlanList,
  autoPay,
  captureCardForFinance,
  insertRetailUser,
  captureCardForKosherDevice,
  saveToHubSpot,
  getSalesTaxPercent

};


