import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import LandingPage from "./pages/LandingPage/LandingPage.jsx";
import MobileSelectionPage from "./pages/mobile_selection_page/mobile_selection_page.jsx";
import ReviewBagPage from "./pages/Review_bag_page/Review_bag_page.jsx";
import TabSelectionPage from "./pages/tab-selection-page/tab_selection_page.jsx";
import PurePlusSection from "./pages/Apps_selection_page/pure-plus.jsx";
import PureSection from "./pages/Apps_selection_page/pure.jsx";
import PureLightSection from "./pages/Apps_selection_page/purelight.jsx";
import BusinessSection from "./pages/Apps_selection_page/bussiness.jsx";
import BasicSection from "./pages/Apps_selection_page/basic.jsx";
import ProductPage from "./pages/Product_page/product_page.jsx";
import Checkout from "./pages/CheckoutPage/Checkout.jsx";
import LogRocket from "logrocket";
import uniFunctions from "./Utils/universalFunctions.js";
import MobileViewHeader from "./pages/Header/MobileView/MobileViewHeader.jsx";
import Header from "./pages/Header/DesktopView/DesktopHeader.jsx";
import { VerifyPageForBoth } from "./pages/mobile_selection_page/VerifyPageForBoth.jsx";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions.jsx";
import Footer from "./pages/Footer/Footer.jsx";
import SupportPage from "./pages/Support_page/Support_page.jsx";
import AboutUs from "./pages/AboutUs/AboutUs.jsx";
import ReactGA from "react-ga";
import NovaMobileSelectionPage from "./pages/NovaMobile/NovaMobile.jsx";
import MaxMusicSelectionPage from "./pages/MaxMusic/MaxMusic.jsx";
import KosherAppStore from "./pages/KosherAppStore/KosherAppStore.jsx";

ReactGA.initialize("G-5YLTPK62PL");

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}

function App() {
  LogRocket.init("gfiuwi/kosher-awolf");

  useEffect(() => {
    if (uniFunctions.isLocalStorageAvailable) {
      console.log("localStorage is available on App.js.");
      localStorage.removeItem("mobileSelectionState");
      localStorage.removeItem("checkOutState");
      localStorage.removeItem("tabSelectionState");
    } else {
      console.log(
        "localStorage is not available on App.js, Consider an alternative solution."
      );
    }
  }, []);

  // Media query to detect screen size less than 700px
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  return (
    <Router>
      <PageViewsHandler />
      {isMobile ? <MobileViewHeader /> : <Header />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/mobile-selection" element={<MobileSelectionPage />} />
        <Route path="/tab-selection" element={<TabSelectionPage />} />
        <Route path="/nova-selection" element={<NovaMobileSelectionPage />} />
        <Route path="/max-music" element={<MaxMusicSelectionPage />} />
        <Route path="/build-app-store" element={<KosherAppStore />} />
        <Route path="/pure-plus" element={<PurePlusSection />} />
        <Route path="/pure" element={<PureSection />} />
        <Route path="/light" element={<PureLightSection />} />
        <Route path="/business" element={<BusinessSection />} />
        <Route path="/basic" element={<BasicSection />} />
        <Route path="/our-products" element={<ProductPage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/verify-page" element={<VerifyPageForBoth />} />
        <Route path="/review-bag" element={<ReviewBagPage />} />
        <Route path="/info" element={<Footer />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

function PageViewsHandler() {
  usePageViews();
  return null;
}

export default App;
