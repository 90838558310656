// Header.jsx
import React, { useState } from "react";
import "./DesktopHeader.css";
import logo from "../../../assets/logo.png";
import smallMosher from "../../../assets/mosher-small.png";
import emptyCart from "../../../assets/empty-cart.png";
import { Link } from "react-router-dom"; // Import the Link component
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const pageNavigation = (page) => {
    navigate(page);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <img src={smallMosher} alt="Maxsip PURE" className="logo" />
        </Link>
        <Link to="/">
          <img src={logo} alt="Maxsip PURE" className="logo" />
        </Link>
      </div>

      <nav className="navigation">
        <ul className="nav-links">
        <li>
            <a
              className="pageNavigation"
              onClick={() => pageNavigation("/")}
            >
              Home
            </a>
          </li>
          <li
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
          >
            
            <a>
              Kosher Store <span className="dropdown-icon"></span>
            </a>

            {isDropdownVisible && (
              <div className="dropdown">
                <ul>
                  <li>
                    <Link to="/pure-plus">Pure +</Link>
                  </li>
               
                  <li>
                    <Link to="/build-app-store">Customize Store</Link>
                  </li>
                </ul>
              </div>
            )}
          </li>

          <li>
            <a
              className="pageNavigation"
              onClick={() => pageNavigation("our-products")}
            >
              Products
            </a>
          </li>
          <li>
            <a href="/support">Support</a>
          </li>
          <li>
            <a href="/about-us">About Us</a>
          </li>
        </ul>
      </nav>
      <div className="cart">
        <img
          onClick={() => pageNavigation("checkout")}
          src={emptyCart}
          alt="Cart"
        />
      </div>
    </header>
  );
};

export default Header;
