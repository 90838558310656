import React, { useEffect, useState } from "react";
import "./AppSelectionPage.css"; // Ensure this file contains necessary styles
import scrollUp from "../../assets/scrollup.png";
import { isMobile } from "react-device-detect";
import { firestore2 } from "../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const PurePlusSection = () => {
  const [apps, setApps] = useState([]);
  const [groupedApps, setGroupedApps] = useState({});

  useEffect(() => {
    const fetchApps = async () => {
      const appsCollection = collection(firestore2, "apps");
      const snapshot = await getDocs(appsCollection);
      const appsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      setApps(appsList);
      groupAppsByCategory(appsList);
    };

    fetchApps();
  }, []);

  const groupAppsByCategory = (apps) => {
    const priorityCategories = [
      "Business",
      "Educational",
      "Banking",
      "Medical",
      "Travel",
      "Finance",
      "Tools",
      "Entertainment",
      "Transportation",
      "Social",
      "Torah",
    ];

    const grouped = apps.reduce((acc, app) => {
      const category = app.data.category
        ? app.data.category.toLowerCase()
        : "other";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(app);
      return acc;
    }, {});

    const orderedGrouped = {};
    priorityCategories.forEach((category) => {
      const lowerCategory = category.toLowerCase();
      if (grouped[lowerCategory]) {
        orderedGrouped[category] = grouped[lowerCategory];
      }
    });

    orderedGrouped["Other"] = grouped["other"] || [];

    setGroupedApps(orderedGrouped);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  };

  return (
    <div>
      <div className={isMobile ? "app-selection-mobile" : "app-selection"}>
        <h1>Pure +</h1>
        <div className="app-categories">
          {Object.entries(groupedApps).map(([category, apps]) => (
            <div key={category} className="app-category">
              <center>
                {" "}
                <h2>{category}</h2>
              </center>
              <div className="app-category-list">
                {apps.map((app) => (
                  <div key={app.id} className="app-item-plus">
                    <img src={app.data.logoUrl} alt={app.data.appName} />
                    <p>{app.data.appName.split(" ")[0]}</p>{" "}
                    {/* Display only the first word of the app name */}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <button onClick={scrollToTop} className="scroll-to-top-btn">
        <img src={scrollUp} alt="Scroll to top" />
      </button>
    </div>
  );
};

export default PurePlusSection;
