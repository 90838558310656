import React, { useEffect, useRef } from 'react';
import './SwatchAnimation.css';

const SwatchAnimation = () => {
  const swatchRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          swatchRef.current.classList.add('animate');
        } else {
          swatchRef.current.classList.remove('animate');
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    if (swatchRef.current) {
      observer.observe(swatchRef.current);
    }

    return () => {
      if (swatchRef.current) {
        observer.unobserve(swatchRef.current);
      }
    };
  }, []);

  return (
    <div className="mobile-scattered-animation">
      <div className="swatch" ref={swatchRef}>
        <img src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/static-images%2Fgrey.png?alt=media&token=511036f1-6ff2-4817-b990-96baead5c93c" alt="Swatch 1" className="swatch-item grey" />
        <img src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/static-images%2Fmajesty.png?alt=media&token=c82dabfc-8964-41b2-a963-c1631e02c848" alt="Swatch 2" className="swatch-item majesty" />
        <img src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/static-images%2Fnavy.png?alt=media&token=53f35bea-db6c-42f4-a9f7-cd166be3c947" alt="Swatch 3" className="swatch-item navy" />
        <img src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/static-images%2Fpearl.png?alt=media&token=c7351b33-607f-4836-96bf-99447dc88b1f" alt="Swatch 4" className="swatch-item black" />
        <img src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/static-images%2Fblack%205.png?alt=media&token=c4a4647c-14ab-4bed-97cc-75d1ed795b5e" alt="Swatch 5" className="swatch-item pearl" />
        <img src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/static-images%2Fwhite.png?alt=media&token=7377b42f-022f-4a65-9c40-7fc6a25cc901" alt="Swatch 6" className="swatch-item white" />
        <img src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/static-images%2Fphone.png?alt=media&token=3e8eb698-2f1a-467b-a86e-1922112ae468" alt="Swatch 7" className="swatch-item kosher-front" />


      </div>
    </div>
  );
};

export default SwatchAnimation;
