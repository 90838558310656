import React, { useEffect, useState } from "react";
import "./InitialPopUpMenu.css";
import { firestore } from "../../../firebaseConfig";
import { setDoc, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const InitialPopUpMenu = () => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [claimed, setClaimed] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!email) {
      setError("Email is required");
      return;
    }

    const discountEmailData = {
      email,
      phone,
      timestamp: serverTimestamp(),
      claimed,
    };

    try {
      const docRef = doc(firestore, "discount-emails", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setError("Email has already claimed the discount.");
      } else {
        await setDoc(docRef, discountEmailData);
        setSuccess(true);
        setEmail("");
        setPhone("");
        setIsVisible(false); // Hide the original popup
      }
    } catch (err) {
      setError("Failed to claim discount. Please try again.");
      console.error("Error adding document: ", err);
    }
  };

  const handleSelectDevice = () => {
    navigate("/our-products");
  };

  return (
    <>
      {isVisible && (
        <div className="popup-container">
          <div className="popup-content">
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <div className="popup-header">
              <h2>Welcome!</h2>
            </div>
            <div className="thank-u-text">
              Thank you for visiting Maxsip Pure.
            </div>
            <p>
              Enter your email for <strong>3 Months</strong> free service.
            </p>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="tel"
                placeholder="Phone Number (Optional)"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <button type="submit" className="claim-button">
                CLAIM DISCOUNT
              </button>
            </form>
            <p className="no-thanks" onClick={handleClose}>
              No Thanks
            </p>
          </div>
        </div>
      )}

      {success && (
        <div className="popup-container">
          <div className="popup-content">
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <h2>Success!</h2>
            <p className="success-message">
              You are eligible to claim $150 off during checkout. Please select
              the device and claim your discount. Thanks
            </p>
            <button
              className="select-device-button"
              onClick={handleSelectDevice}
            >
              Select Device
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InitialPopUpMenu;
