import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiFunc from "../../Utils/utilApisFunctions";
import Swal from 'sweetalert2';
import './VerifyPageForBoth.css'; // Ensure you have this CSS file for styling

export const VerifyPageForBoth = () => {
    const deviceMappingForId = {
        G10: "185",
        G62: "154",
        G65: "152",
        X8G: "148",
      };
    
      const priceMapping = {
        $15: "2789", $20: "2907", $25: "3025", $35: "3143",
      };
    
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedKosherLevel, selectedColor, selectedBuyOption, selectedPrice, selectedDevise } = location.state || {};

    // State to manage loading indicator visibility
    const [isLoading, setIsLoading] = useState(false);
    const getDeviceId = (deviceKey) => {
        return deviceMappingForId[deviceKey] || null; // Returns the mapped id or null if not found
      };

      const getPriceMapping = (planKey) => {
        return priceMapping[planKey] || null; // Returns the mapped id or null if not found
      };
    const checkServiceAvailability = async () => {
        setIsLoading(true); // Show loader
        const deviceId = getDeviceId(selectedDevise); // Get the device id based on the selected device
        const planId = getPriceMapping(selectedPrice); // Get the device id based on the selected device
        if (!deviceId) {
          Swal.fire({
            title: 'Error!',
            text: 'Invalid device selection.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
          setIsLoading(false);
          return;
        }
        try {
            navigate('/checkout', {
                state: {
                  deviceId: deviceId,
                  planId: planId,
                  selectedKosherLevel: selectedKosherLevel,
                  selectedColor: selectedColor,
                  selectedBuyOption: selectedBuyOption,
                  deviceName: selectedDevise,
                },
              });
              setIsLoading(false);

        } catch (error) {
            console.error("Error calling the service availability API", error);
            Swal.fire({
                title: 'API Error',
                text: 'Failed to check service availability.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
        }
    };


    useEffect(() => {
        checkServiceAvailability();
    }, []);

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return (
      <div style={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <div style={{
        minWidth: '300px',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        overflowX: 'auto'
    }}>
        <h2 style={{ textAlign: 'center' }}>Selection Summary</h2>
        <table style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px'
        }}>
            <tbody>
                <tr style={{
                    backgroundColor: '#f2f2f2',
                    borderBottom: '1px solid #ddd'
                }}>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Aspect</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Selection</th>
                </tr>
                <tr>
                    <td style={{ padding: '8px' }}>Kosher Level</td>
                    <td style={{ padding: '8px' }}>{selectedKosherLevel || 'N/A'}</td>
                </tr>
                <tr style={{ backgroundColor: '#f2f2f2' }}>
                    <td style={{ padding: '8px' }}>Color</td>
                    <td style={{ padding: '8px' }}>{selectedColor || 'N/A'}</td>
                </tr>
                <tr>
                    <td style={{ padding: '8px' }}>Buy Option</td>
                    <td style={{ padding: '8px' }}>{selectedBuyOption || 'N/A'}</td>
                </tr>
                <tr style={{ backgroundColor: '#f2f2f2' }}>
                    <td style={{ padding: '8px' }}>Price</td>
                    <td style={{ padding: '8px' }}>{selectedPrice || 'N/A'}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

    );
};
