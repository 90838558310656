import React, { useEffect, useRef } from "react";
import "./UltimateSolution.css"; // Import the CSS stylesheet

const UltimateSolution = () => {
  const upperRef = useRef(null);
  const lowerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      },
      {
        threshold: 0.1, // Trigger when at least 10% of the element is visible
      }
    );

    if (upperRef.current) observer.observe(upperRef.current);
    if (lowerRef.current) observer.observe(lowerRef.current);

    return () => {
      if (upperRef.current) observer.unobserve(upperRef.current);
      if (lowerRef.current) observer.unobserve(lowerRef.current);
    };
  }, []);

  return (
    <div>
      <div className="ultimate-solution" ref={upperRef}>
        <div className="image-container">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/random-images%2FVtox6rY%20-%20Imgur.png?alt=media&token=ff4ddb3c-d411-4497-8fcf-bc8e0bf47879"
            alt="Maxsip Pure Devices"
            className="devices-image"
          />
        </div>
        <div className="info-container">
          <h2>Kosher App Store</h2>
          <ul>
            <li style={{ marginBottom: "5px", fontSize: "20px" }}>
              Need access to business and finance tools?
            </li>
            <li style={{ marginBottom: "5px", fontSize: "20px" }}>
              Want to limit your access to purely Torah Apps?
            </li>
          </ul>
          <p style={{ marginBottom: "5px", fontSize: "18px" }}>
            No problem! Just pick the Kosher Apps and build your own App Store.
          </p>
        </div>
      </div>

      <div className="ultimate-solution" ref={lowerRef}>
        <div className="info-container">
          <h2>Browser-Free – No Workarounds </h2>
          <p>
            We’re proud to provide a truly browser-free technological
            experience. All of our products have been submitted to months of
            rigorous testing – there’s no way to bypass our restrictions and
            access unapproved content.{" "}
          </p>
        </div>
        <div className="image-container image-container-lower">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/random-images%2FpVbYT9X%20-%20Imgur.png?alt=media&token=d98a7f47-a54c-4bb5-9032-318806d80b9c"
            alt="Maxsip Pure Devices"
            className="devices-image"
          />
        </div>
      </div>
    </div>
  );
};

export default UltimateSolution;
