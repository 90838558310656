// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// First Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyAp972RWWv5WppdXMIQPy_fVN9z4JiEulg",
  authDomain: "kosher-web.firebaseapp.com",
  projectId: "kosher-web",
  storageBucket: "kosher-web.appspot.com",
  messagingSenderId: "1032748996702",
  appId: "1:1032748996702:web:b31c926392e006c8043ccf",
  measurementId: "G-5YLTPK62PL"
};

// Second Firebase Configuration
const firebaseConfig2 = {
  apiKey: "AIzaSyCdhKzDv3JPC-Q-ZwMwXQhtawSq4oWSpLY",
  authDomain: "appstore-81cb7.firebaseapp.com",
  projectId: "appstore-81cb7",
  storageBucket: "appstore-81cb7.appspot.com",
  messagingSenderId: "317413686434",
  appId: "1:317413686434:web:68d1c3204f6827cd122bfb"
};

// Initialize the first Firebase app
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

// Initialize the second Firebase app with a specific name
const app2 = initializeApp(firebaseConfig2, "secondary");
export const firestore2 = getFirestore(app2);
