import React, { useState, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { DesktopSide } from "./Desktop_view/DesktopView";
import LandingPageMobileView from "./MobileView/MobileViewLandingPage";
import InitialPopUpMenu from "./InitialPopUpMenu/InitialPopUpMenu";

function LandingPage() {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("down");

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const viewportHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScroll = documentHeight - viewportHeight;

      const scrollPositionInVh = (scrollTop / maxScroll) * 100;
      setScrollProgress(scrollPositionInVh);

      // Determine the direction of scrolling
      const direction = scrollTop > prevScrollPosition ? "down" : "up";
      setScrollDirection(direction);
      console.log(
        "Scroll Progress (vh):",
        scrollPositionInVh,
        "Scroll Direction:",
        direction
      );

      setPrevScrollPosition(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPosition]);

  const [text, setText] = useState(0);

  const buyNowButtonStyle = {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "10px 20px",
    backgroundColor: "#00254a",
    color: "#fff",
    border: "none",
    borderRadius: "25px",
    cursor: "pointer",
    zIndex: 1000,
    
  };

  const handleBuyNowClick = () => {
    window.location.href = "/our-products";
  };

  return (
    <div>
      <InitialPopUpMenu />
      <BrowserView>
        <DesktopSide setText={setText} scrollProgress={scrollProgress} />
      </BrowserView>
      <MobileView>
        <LandingPageMobileView />
      </MobileView>
      <button style={buyNowButtonStyle} onClick={handleBuyNowClick}>
        Order Now
      </button>
    </div>
  );
}

export default LandingPage;
