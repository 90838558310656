import React from 'react';
import logo from '../../assets/logo.png'; // Make sure to use the correct path to your logo image file
import './AboutUs.css'; // Path to your AboutUs.css file
import Footer from "../Footer/Footer";

const AboutUs = () => {
  return (
    <div>
      <div className="aboutus-container">
        <div className="aboutus-header">
          <img src={logo} alt="Maxsip PURE" className="aboutus-logo" />
          <h1>About Us</h1>
        </div>
        <div className="aboutus-content">
          <p>
            <strong>Our Story:</strong> Maxsip Pure was founded in February 2021 in Valley Stream, New York, aiming to allow individuals and organizations to access the benefits of modern technology without the risks of inappropriate content.
          </p>
          <p>
            <strong>Our Technology:</strong> The cornerstone of Maxsip Pure’s offerings is the revolutionary PureOS operating system, a custom-built solution balancing modern technology opportunities with personal standards.
          </p>
          <p>
            <strong>Our Commitment:</strong> We have optimized security, reliability, and device quality, focusing on flexibility to provide high-quality, certified kosher devices tailored to users’ unique needs.
          </p>
          <p>
            <strong>Our Partnerships:</strong> We partner exclusively with device manufacturers to ensure uncompromising quality.
          </p>
          <p>
            <strong>Kosher Certification:</strong> Maxsip Pure is under the strict supervision of Rabbi David Churba of Tech Flatbush (Machane Kadosh), who tests our devices, approves curated apps, and guides our continuous product refinement.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
