import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import './WhyChooseMaxsip.css'; // Ensure CSS is imported

const WhyChooseMaxsip = () => {
  return (
    <div className="why-choose-maxsip-container">
      <Swiper
        // spaceBetween={10} // Reduced space between slides
        // slidesPerView={1.2} // Show a little bit of the next slide
        // centeredSlides={false} // Do not center the slides
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <div className="feature-card">
            <h2>Browser-Free – No Workarounds</h2>
            <p>We’re proud to provide a truly browser-free technological experience. All of our products have been submitted to months of rigorous testing – there’s no way to bypass our restrictions and access unapproved content.</p>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="feature-card">
            <h2>Kosher Applications</h2>
            <p>All Maxsip Pure Devices are arrange according to kosher levels.</p>
            <img src="https://firebasestorage.googleapis.com/v0/b/maxsip-customer.appspot.com/o/image%2083.png?alt=media&token=ae85fd28-e7c9-40e9-81b3-a55d7fa447d4" alt="Rabbanism Icon" />
          </div>
        </SwiperSlide> */}
        {/* You can add more SwiperSlide components here */}
      </Swiper>
    </div>
  );
}

export default WhyChooseMaxsip;
