import data from "../Choose_plan_page/data";
import React from "react";
import "./DesktopView.css";
import KosherIcon from "../../../assets/frontIcons/kosher-icon.png";
import WarrantyIcon from "../../../assets/frontIcons/warranty-icon.png";
import PrivacyIcon from "../../../assets/frontIcons/privacy-icon.png";
import customerServiceIcon from "../../../assets/frontIcons/customer-service-icon.png";
import tab from "../../../assets/choose-your-phone.png";
import DeviceSelectionCard from "../DeviceSelectionCard/Device_selection_card";
import tabLogo from "../../../assets/pure-tab-with-logo.png";
import mobile from "../../../assets/pure-mobile-with-logo.png";
import ChooseYourPlan from "../Choose_plan_page/Choose_your_plan";
import TabImg from "../../../assets/main-tab-image.png";
import backgroundImage from "../../../assets/banner-img.png";
import mainPhoneImg from "../../../assets/main-phoneimg.png";
import whiteBg from "../../../assets/white-bg.jpg";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
import HomePageProducts from "./HomePageProducts/HomePageProducts";
import UltimateSolution from "./UltimateSolutionPage/UltimateSolution";
import KosherImageSlider from "../ImageSlider/KosherImageSlider";
import BottomOrderPage from "../BottomOrderPage/BottomOrderPage";
import KosherAppStore from "../../KosherAppStore/KosherAppStore";
import SwatchAnimation from "../SwatchAnimation/SwatchAnimation";
import ImageSpreader from "../ImageSpreader/ImageSpreader";

export const DesktopSide = ({ setText, scrollProgress }) => {
  const navigate = useNavigate();
  const handleBuyNowClick = () => {
    navigate("/our-products");
  };

  const handleWheel = (event) => {
    const scrollSpeed = 0.2;

    const deltaY = event.deltaY * scrollSpeed;
    const scrollDiv = event.currentTarget;
    scrollDiv.scrollTop += deltaY;

    event.preventDefault();
  };
  return (
    <div>
      <KosherImageSlider />


      <UpperContainer handleBuyNowClick={handleBuyNowClick} />
      <ImageSpreader/>

      <div className="container">
        <div className="card">
          <div className="background-img">
            <div style={{ width: "80vw" }}>
              {/* <div
                className="landing-text"
                onClick={() => setText((prev) => prev + 1)}
              >
                Purely Guaranteed
              </div> */}

              {/* <AnimatePresence>
                {scrollProgress > 25 && (
                  <motion.div
                    initial={{
                      y: 80,
                      opacity: 0,
                    }}
                    animate={{
                      y: 30,
                      opacity: 1,
                    }}
                    exit={{
                      y: -80,
                      opacity: 0,
                    }}
                    transition={{ duration: 1 }}
                  >
                    <div className="left-box-text-1">Kosher</div>
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {scrollProgress > 40 && (
                  <motion.div
                    initial={{
                      y: 100,
                      opacity: 0,
                    }}
                    animate={{
                      y: 30,
                      opacity: 1,
                    }}
                    exit={{
                      y: 100,
                      opacity: 0,
                    }}
                    transition={{ duration: 1 }}
                  >
                    <div
                      style={{
                        zIndex: "10 !important",
                        position: "absolute",
                        top: "-10px",
                      }}
                    >
                      <div className="left-box-text-2">Customer</div>
                      <div className="left-box-text-2">Services</div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {scrollProgress > 55 && (
                  <motion.div
                    initial={{
                      y: 100,
                      opacity: 0,
                    }}
                    animate={{
                      y: 30,
                      opacity: 1,
                    }}
                    exit={{
                      y: 100,
                      opacity: 0,
                    }}
                    transition={{ duration: 1 }}
                  >
                    <div className="left-box-text-3">Warranty</div>
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {scrollProgress > 65 && (
                  <motion.div
                    initial={{
                      y: 100,
                      opacity: 0,
                    }}
                    animate={{
                      y: 30,
                      opacity: 1,
                    }}
                    exit={{
                      y: 100,
                      opacity: 0,
                    }}
                    transition={{ duration: 1 }}
                  >
                    <div className="left-box-text-4">Privacy</div>
                  </motion.div>
                )}
              </AnimatePresence> */}
            </div>

            {/* <div className="main-img-flex">
              <img className="main-tab-image" src={TabImg} alt="Kosher" />
              <img
                className="main-phone-image"
                src={mainPhoneImg}
                alt="Kosher"
              />
            </div> */}
          </div>
        </div>
        {/* <div className="card">
          <div className="right-box" onWheel={handleWheel}>
            <img className="icons" src={KosherIcon} alt="Kosher" />
            <div className="text-container">
              <div className="upper-text">Kosher</div>
              <div className="lower-text">
                Uncompromising Kosher quality, overseen by the Tech of Flatbush
                Board of Rabbis
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="right-box2 text1" onWheel={handleWheel}>
            <img className="icons" src={customerServiceIcon} alt="Kosher" />
            <div className="text-container">
              <div className="upper-text">Customer Service</div>
              <div className="lower-text">
                Exclusive and highly trained tech support team ready to assist
                in a timely manner
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="right-box3" onWheel={handleWheel}>
            <img className="icons" src={WarrantyIcon} alt="Kosher" />
            <div className="text-container">
              <div className="upper-text">Warranty</div>
              <div className="lower-text">
                Comprehensive 12-month full coverage warranty with an option to
                extend
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="right-box4" onWheel={handleWheel}>
            <img className="icons" src={PrivacyIcon} alt="Kosher" />
            <div className="text-container">
              <div className="upper-text">Privacy</div>
              <div className="lower-text">
                Confidential user experience where your information is securely
                protected
              </div>
            </div>
          </div>
        </div> */}
        {/* <img className="white-bg-img" src={whiteBg} alt="Kosher" /> */}
      </div>
      <div className="your-choice-pure">
        <div className="first-slide-inner">
          <h2>Your Choice, Pure And Simple!</h2>

          <div className="buttons-container">
            <p>Kosher Approved</p>
            <p>Kosher App Store</p>
            <p>Browser Free</p>
          </div>

          <div className="first-slide-inner-button-and-img">
            <button className="buy-now-button-img" onClick={handleBuyNowClick}>
              Order Now
            </button>
            {/* <img
              src={tab}
              alt="Tablet with Kosher App"
              className="tablet-image"
            /> */}

                  <SwatchAnimation/>

          </div>
        </div>
        {/* <center className="select-device-text">
          <h1>Select Your Device</h1>
        </center> */}
      </div>
      <HomePageProducts />

      <div className="device-selection-scroll-page">
        <DeviceSelectionCard
          deviceType="Smart Phone"
          price="$99.99 or $9.99/month for 12 months"
          features={[
            "Customized OS",
            "Sim-Locked to Device",
            "Kosher App Store",
          ]}
          imageUrl={mobile} // Replace with the actual path to your image
          colorOptions={[]} // Replace with the actual color hex codes
        />
        <DeviceSelectionCard
          deviceType="Tablet"
          price="$99.99 or $9.99/month for 12 months"
          features={[
            "Customized OS",
            "Sim-Locked to Device",
            "Kosher App Store",
          ]}
          imageUrl={tabLogo} // Replace with the actual path to your image
          colorOptions={[]} // Replace with the actual color hex codes
        />
      </div>
      <UltimateSolution />
      <BottomOrderPage />

      {/* <ChooseYourPlan data={data} activeSlide={2} /> */}
      <Footer />
    </div>
  );
};

const UpperContainer = ({ handleBuyNowClick }) => {
  return (
    <div>
      <div
        className="upper-container"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="header-with-button">
          <div className="header-text">PURE connection in one touch</div>
          <button className="buy-now-button" onClick={handleBuyNowClick}>
            Order Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpperContainer;
