// Footer.jsx
import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons';



const Footer = () => {
  return (
    <>
      <footer class="footer-distributed">
        <div class="footer-left">
        <div
        className="mobile-view-footer-logo"
      >
        maxsip <span>PURE</span>
      </div>
          <p class="footer-links">
            <a href="#" class="link-1">
              Home
            </a>

            <a href="/about-us">About Us</a>

            <a href="/support">FAQ</a>

            <a href="/about-us">Contact</a>
          </p>

        </div>

        <div class="footer-center">
          <div>
            <i class="fa fa-map-marker"></i>
            <p>
              <span>P.O BOX 351 Valley Stream, NY 11582</span>
            </p>
          </div>

          <div>
            <i class="fa fa-phone"></i>
            <p>(516) 866-7321</p>
          </div>

          <div>
            <i class="fa fa-envelope"></i>
            <p>
              <a href="mailto:Support@maxsippure.com">Support@maxsippure.com</a>
            </p>
          </div>
          <div>
            <i class="fa fa-envelope"></i>
            <p>
              <a>v2.2</a>
            </p>
          </div>
        </div>

        <div class="footer-right">
          <p class="footer-company-about">
            <span>About the company</span>
            Maxsip Telecom provides communication solutions that are affordable and accessible to all. As a leading telecommunications provider, Maxsip Telecom ensures that everyone can stay connected.
          </p>

          <div className="footer-icons">
                        <a href="https://www.facebook.com/MaxsipTel/">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="https://www.instagram.com/maxsiptel/?hl=en">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.linkedin.com/company/maxsiptel/">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                       
                    </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
