import React from 'react';
import './HomePageProducts.css'; // Import the CSS stylesheet

const HomePageProducts = () => {
  return (
    <div className="home-page-products">
      <div className="product-description">
        <h1>Our products</h1>
        <p>
          Experience the pinnacle of mobile innovation with Maxsip Pure
          smartphones and tablets. Seamlessly blending style, functionality,
          and security, our products offer unparalleled performance and peace
          of mind in every interaction.
        </p>
      </div>
      {/* <div className="product-images">
        <div className="smartphone">
          <img src="https://imgur.com/RuFlwDV.png" alt="Smartphone" />
          <p>Smartphone<br/>6.5 inch</p>
        </div>
        <div className="tablet">
          <img src="https://imgur.com/98Gud6Q.png" alt="Tablet" />
          <p>Tablet<br/>8 inch or 10 inch</p>
        </div>
      </div> */}
    </div>
  );
};

export default HomePageProducts;
