// AppSelection.jsx
import React from 'react';
import './AppSelectionPage.css'; // Create and link this CSS file for styles
import business from '../../assets/bussiness.png'; 
import scrollUp from "../../assets/scrollup.png";
import {isMobile} from 'react-device-detect';



const BusinessSection = () => {

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll to top
    });
  }
  
  return (
    <div><div className={isMobile ? "app-selection-mobile" : "app-selection"}>
    <h1>Business</h1>
  <div>
    <img src={business} alt="" />
  </div>    
  </div>
  <button onClick={scrollToTop} className="scroll-to-top-btn">
  <img src={scrollUp} alt="Scroll to top" />
</button> </div>
    
  );
};

export default BusinessSection;
