import React from "react";
import "./BottomOrderPage.css";
import { useNavigate } from "react-router-dom";

const BottomOrderPage = () => {
  const navigate = useNavigate();
  const handleBuyNowClick = () => {
    navigate("/build-app-store");
  };

  return (
    <div className="bottom-order-page">
      <div className="top-section">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2FAdobeStock_240736156%20(1)%201.png?alt=media&token=f0844a41-f9cc-462f-9bbc-6fbb6f541c55"
          alt="Maxsip Pure"
          className="product-image"
        />
        <div className="product-details">
          <h1>Maxsip Pure: The Ultimate Kosher Smartphone</h1>
          <p>- Starting at $9.99/month for 12 months</p>
        </div>
      </div>
      <div className="info-section">
        <p>
          In today's fast-paced world, the need for a high performance Kosher
          Smartphone that respects Torah values has never been greater. Maxsip
          Pure smartphones and tablets are specifically designed to cater to the
          Orthodox Jewish community, blending practicality, security, and
          unmatched performance. Our products ensure you stay productive while
          adhering to Torah standards and principles.
        </p>
      </div>
      <div className="kosher-level-section">
        <h2>Build Your App Store</h2>
        <p>
          At Maxsip Pure, we empower you to create your own personalized app
          store, filled with a wide range of kosher-approved apps tailored to
          your specific needs and preferences. Whether you're looking for
          essential tools for everyday use, business-related apps for
          professional tasks, or a comprehensive digital experience with a
          mindful selection of entertainment options, our customizable app store
          allows you to choose from a vast library of kosher apps that align
          with your values and lifestyle.
        </p>
        <p>
          Simply select the apps that best suit your needs, and create a
          streamlined, distraction-free digital environment that supports your
          day-to-day activities while adhering to kosher guidelines. With Maxsip
          Pure, you're in control of your digital experience, ensuring it’s both
          respectful and fulfilling.
        </p>

        <div className="app-categories-section">
          <h2>App Categories</h2>
          <ul>
            <li>
              <strong>Educational:</strong> Dive into a world of learning with
              apps that cover a wide range of subjects, from language learning
              to science and mathematics. These apps are designed to support
              educational growth and intellectual curiosity in a safe
              environment.
            </li>
            <li>
              <strong>Finance:</strong> Manage your finances with confidence.
              Our finance apps provide tools for budgeting, investing, and
              managing your financial portfolio, all within a kosher framework.
            </li>
            <li>
              <strong>Banking:</strong> Access your bank accounts securely and
              perform transactions with ease. These apps ensure that your
              banking needs are handled efficiently while maintaining the
              highest standards of security.
            </li>
            <li>
              <strong>Games:</strong> Enjoy a selection of kosher-approved games
              that provide entertainment without compromising on values. These
              games are fun, engaging, and perfect for all ages.
            </li>
            <li>
              <strong>Social:</strong> Connect with friends and family through
              apps designed for communication and social interaction. These apps
              help you stay in touch with loved ones while adhering to kosher
              principles.
            </li>
         
            <li>
              <strong>Religious Apps:</strong> Enhance your religious practice
              with tools that support daily prayers, holiday observances, and
              other religious activities. These apps are designed to help you
              maintain a strong connection to your faith.
            </li>
          </ul>
        </div>

        <div className="browser-free-section">
          <h2>Truly Browser-Free</h2>
          <p>
            We pride ourselves on providing a truly browser-free phone
            experience, ensuring that our products are safe and appropriate for
            our community. All Maxsip Pure products undergo rigorous testing to
            ensure there are no workarounds to access unapproved content.
          </p>
        </div>

        <div className="order-now-section">
          <h2>Build Now</h2>
          <p>
            Take advantage of our special pricing and experience secure and
            respectful mobile innovation with Maxsip Pure.
          </p>
        
        </div>

        <center>
          {" "}
          <button className="order-now-button" onClick={handleBuyNowClick}>
            Click Here To Build App Store
          </button>
        </center>
      </div>
    </div>
  );
};

export default BottomOrderPage;
