export default [
  {
    id: 3,
    bgColor: "#B2DFDB", // Original: #E0F2F1, made sharper
    // icon: <FontAwesomeIcon icon={faDragon} size="3x" />,
    title: "Pure Light",
    subtitle: "Fewer Apps",
  },
  {
    id: 2,
    bgColor: "#FFC1C3", // Original: #FFE4E6, made sharper
    // icon: <FontAwesomeIcon icon={faGlassCheers} size="3x" />,
    title: "Pure",
    subtitle: "Fewer Entertainment Apps",
  },

  {
    id: 1,
    bgColor: "#D1C4E9", // Original: #EDE9FE, made sharper
    // icon: <FontAwesomeIcon icon={faCameraRetro} size="3x" />,
    title: "Pure +",
    subtitle: "Has Over 500+ Apps",
  },
  {
    id: 4,
    bgColor: "#FFF59D", // Original: #FFFDE7, made sharper
    // icon: <FontAwesomeIcon icon={faFootballBall} size="3x" />,
    title: "Business",
    subtitle: "All Business Related apps",
  },
  {
    id: 5,
    bgColor: "#BBDEFB", // Original: #E3F2FD, made sharper
    // icon: <FontAwesomeIcon icon={faHelicopter} size="3x" />,
    title: "Basic",
    subtitle: "Basic Banking Apps",
  },
];
