import React from "react";
import "./PopupMenu.css"; // Make sure to define styles for .popupMenu-overlay and .popupMenu-content

const PopupMenu = ({
  onClose,
  selectedColor,
  setSelectedColor,
  selectedKosherLevel,
  setSelectedKosherLevel,
  selectedBuyOption,
  setSelectedBuyOption,
  deviceName0,
  SelectedPlanPrice0,
  setSelectedPlanPrice,
}) => {
  const colors = ["black", "white", "turq", "pink", "navy", "grey"];
  // const colors = ["black", ];

  const kosherLevels = [
    "PURE +",
    "PURE",
    "Business",
    "Basic",
    "Pure Light",
    "Waze Only",
    "Zoom Only",
  ];
  const buyOptions = ["Buy", "option2", "option3"];
  const planOptions = [
    { label: "10GB Data", value: "$35" },
    { label: "5GB Data", value: "$25" },
    { label: "3GB Data", value: "$20" },
    { label: "1GB Data", value: "$15" },
  ];

  const filteredColors =
    deviceName0 !== "G65"
      ? colors.filter((color) => color === "black")
      : colors;

  const handlePlanChange = (value) => {
    setSelectedPlanPrice(value);
  };

  return (
    <div className="popupMenu-overlay">
      <div className="popupMenu-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        {/* Colors Section */}
        <h3>Colors</h3>
        <div>
          {filteredColors.map((color) => (
            <button
              key={color}
              onClick={() => setSelectedColor(color)}
              className={
                selectedColor.toLowerCase() === color.toLowerCase()
                  ? "selected"
                  : ""
              }
            >
              {color.charAt(0).toUpperCase() + color.slice(1).toLowerCase()}
            </button>
          ))}
        </div>

        {/* Kosher Levels Section */}
        <h3>Kosher Levels</h3>
        <div>
          {kosherLevels.map((level) => (
            <button
              key={level}
              onClick={() => setSelectedKosherLevel(level)}
              className={selectedKosherLevel === level ? "selected" : ""}
            >
              {level}
            </button>
          ))}
        </div>

        {/* Buy Options Section */}
        <h3>Buy Options</h3>
        <div>
          {buyOptions.map((option) => (
            <div
              key={option}
              className={`payment-option-container ${
                selectedBuyOption === option ? "selected" : ""
              }`}
            >
              <button
                className="payment-option-button"
                onClick={() => setSelectedBuyOption(option)}
              >
                {option === "Buy"
                  ? "One time Payment ($99.99)"
                  : option === "option2"
                  ? "1 year contract $9.99/month"
                  : "2 year contract $9.99/month"}
              </button>
            </div>
          ))}
        </div>

        {/* Plan Change Section */}
        <h3>Change Plan</h3>
        <div className="change-plan-popup">
          {planOptions.map(({ label, value }) => (
            <button
              key={value}
              onClick={() => handlePlanChange(value)}
              className={SelectedPlanPrice0 === value ? "selected" : ""}
            >
              {label}
            </button>
          ))}
        </div>

        <button className="done-button" onClick={onClose}>
          Done
        </button>
      </div>
    </div>
  );
};

export default PopupMenu;
