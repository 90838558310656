// images.js

export const top_images = [
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FoF6YSLw%20-%20Imgur.png?alt=media&token=335fd91e-66a7-47b5-8e22-1a0cad91a10a",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FwKWbzZI%20-%20Imgur.png?alt=media&token=317499f1-fc7f-40f0-9bae-d84a71743764",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2Fjaj4W5f%20-%20Imgur.png?alt=media&token=64055e43-2e36-461c-9a8d-4da813061fc9",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FHi5s1W4%20-%20Imgur.png?alt=media&token=2d15a6d1-49e8-4a55-a98d-2f9e8dbf2db2",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FB73OdMc%20-%20Imgur.png?alt=media&token=6d11ec73-ed8a-4ee2-a842-eb23a97839c0",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FWd3UnBU%20-%20Imgur.png?alt=media&token=5bc94c3a-0570-48de-aecb-63ccc8defd99",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FdQktoi1%20-%20Imgur.png?alt=media&token=5f2f0503-9265-4696-9520-ec655617ce1f",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2F9s8jllF%20-%20Imgur.png?alt=media&token=dc3cb4e0-1f51-4366-ac81-b7292cc0a6a4",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2Fv0PBfzB%20-%20Imgur.png?alt=media&token=1a846df6-d43c-4ea1-b16a-f7c0e9ce4815",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FeW8K7vR%20-%20Imgur.png?alt=media&token=03014bfd-3b96-426d-8914-04495d65c857",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FsghMdlB%20-%20Imgur.png?alt=media&token=ce8263ea-6ac2-408a-8a10-db0d8daf574e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FZblsUCT%20-%20Imgur.png?alt=media&token=03b27fe8-c740-4046-b92c-7060fc49e12e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FGlWif6D%20-%20Imgur.png?alt=media&token=a0af248e-e1f4-4f0f-91fd-9eec2395efa1",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FoF6YSLw%20-%20Imgur.png?alt=media&token=335fd91e-66a7-47b5-8e22-1a0cad91a10a",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FwKWbzZI%20-%20Imgur.png?alt=media&token=317499f1-fc7f-40f0-9bae-d84a71743764",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2Fjaj4W5f%20-%20Imgur.png?alt=media&token=64055e43-2e36-461c-9a8d-4da813061fc9",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FHi5s1W4%20-%20Imgur.png?alt=media&token=2d15a6d1-49e8-4a55-a98d-2f9e8dbf2db2",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FB73OdMc%20-%20Imgur.png?alt=media&token=6d11ec73-ed8a-4ee2-a842-eb23a97839c0",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FWd3UnBU%20-%20Imgur.png?alt=media&token=5bc94c3a-0570-48de-aecb-63ccc8defd99",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FdQktoi1%20-%20Imgur.png?alt=media&token=5f2f0503-9265-4696-9520-ec655617ce1f",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2F9s8jllF%20-%20Imgur.png?alt=media&token=dc3cb4e0-1f51-4366-ac81-b7292cc0a6a4",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2Fv0PBfzB%20-%20Imgur.png?alt=media&token=1a846df6-d43c-4ea1-b16a-f7c0e9ce4815",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FeW8K7vR%20-%20Imgur.png?alt=media&token=03014bfd-3b96-426d-8914-04495d65c857",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FsghMdlB%20-%20Imgur.png?alt=media&token=ce8263ea-6ac2-408a-8a10-db0d8daf574e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FZblsUCT%20-%20Imgur.png?alt=media&token=03b27fe8-c740-4046-b92c-7060fc49e12e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FGlWif6D%20-%20Imgur.png?alt=media&token=a0af248e-e1f4-4f0f-91fd-9eec2395efa1",
];

export const middle_images = [
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FR97wvRU%20-%20Imgur.png?alt=media&token=78a7871b-ef75-4ab3-be2f-06b2bf51edc4",
  "https://play-lh.googleusercontent.com/uBUf0kvXiVgitMIsXxDB5PzhbPa9ZUFWXuICakpY7iP6v07FISj0KqzaTqlHX_dZUg",
  // "https://play-lh.googleusercontent.com/LdnJSVeND2SpRUAvJ0xi-xsYHuV3pQJyYbUrlt6XoocjGsswrluZPwc4pTgATxxCBQ",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FWJPrKKB%20-%20Imgur.png?alt=media&token=46cb4368-5ac2-4aaf-9b40-a5d6a8d8f0c4",
  "https://play-lh.googleusercontent.com/bDCkDV64ZPT38q44KBEWgicFt2gDHdYPgCHbA3knlieeYpNqbliEqBI90Wr6Tu8YOw",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FVePozaq%20-%20Imgur%20(1).png?alt=media&token=28ad4752-23e6-4762-ad4d-3619b0e45ffa",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FKzM2ixx%20-%20Imgur.png?alt=media&token=6b82d1e3-7b09-48c3-973b-16643b3ff6b3",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FDf4qaj1%20-%20Imgur.png?alt=media&token=068b95b7-5db6-4cc5-bc35-f6f3d81dca4d",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FOziThDl%20-%20Imgur.png?alt=media&token=f9e3c149-4dba-4f9e-a634-20f25280123e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FmDb1eh7%20-%20Imgur.png?alt=media&token=0fe58d5c-9bdb-4056-8b8f-90e55b4a7ecb",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FtZSZeXc%20-%20Imgur.png?alt=media&token=b7c8e5f0-a902-4428-aeba-6c3cc0eb63f9",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2FDBZQ543%20-%20Imgur.png?alt=media&token=4ba2e4fe-84a0-42a2-bed3-0bbf10a4f86b",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2Fg08wbnF%20-%20Imgur.png?alt=media&token=a8e9c7dc-cb20-4f42-ba16-67960eb6fe83",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/slider-images%2Ffsc0FOU%20-%20Imgur.png?alt=media&token=af50fe2a-04af-4df6-9213-a72be8072af2",
];

export const bottom_images = [
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FdQktoi1%20-%20Imgur.png?alt=media&token=5f2f0503-9265-4696-9520-ec655617ce1f",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2F9s8jllF%20-%20Imgur.png?alt=media&token=dc3cb4e0-1f51-4366-ac81-b7292cc0a6a4",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2Fv0PBfzB%20-%20Imgur.png?alt=media&token=1a846df6-d43c-4ea1-b16a-f7c0e9ce4815",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FeW8K7vR%20-%20Imgur.png?alt=media&token=03014bfd-3b96-426d-8914-04495d65c857",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FsghMdlB%20-%20Imgur.png?alt=media&token=ce8263ea-6ac2-408a-8a10-db0d8daf574e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FZblsUCT%20-%20Imgur.png?alt=media&token=03b27fe8-c740-4046-b92c-7060fc49e12e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FGlWif6D%20-%20Imgur.png?alt=media&token=a0af248e-e1f4-4f0f-91fd-9eec2395efa1",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FoF6YSLw%20-%20Imgur.png?alt=media&token=335fd91e-66a7-47b5-8e22-1a0cad91a10a",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FwKWbzZI%20-%20Imgur.png?alt=media&token=317499f1-fc7f-40f0-9bae-d84a71743764",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2Fjaj4W5f%20-%20Imgur.png?alt=media&token=64055e43-2e36-461c-9a8d-4da813061fc9",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FHi5s1W4%20-%20Imgur.png?alt=media&token=2d15a6d1-49e8-4a55-a98d-2f9e8dbf2db2",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FB73OdMc%20-%20Imgur.png?alt=media&token=6d11ec73-ed8a-4ee2-a842-eb23a97839c0",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FWd3UnBU%20-%20Imgur.png?alt=media&token=5bc94c3a-0570-48de-aecb-63ccc8defd99",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FdQktoi1%20-%20Imgur.png?alt=media&token=5f2f0503-9265-4696-9520-ec655617ce1f",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2F9s8jllF%20-%20Imgur.png?alt=media&token=dc3cb4e0-1f51-4366-ac81-b7292cc0a6a4",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2Fv0PBfzB%20-%20Imgur.png?alt=media&token=1a846df6-d43c-4ea1-b16a-f7c0e9ce4815",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FeW8K7vR%20-%20Imgur.png?alt=media&token=03014bfd-3b96-426d-8914-04495d65c857",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FsghMdlB%20-%20Imgur.png?alt=media&token=ce8263ea-6ac2-408a-8a10-db0d8daf574e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FZblsUCT%20-%20Imgur.png?alt=media&token=03b27fe8-c740-4046-b92c-7060fc49e12e",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FGlWif6D%20-%20Imgur.png?alt=media&token=a0af248e-e1f4-4f0f-91fd-9eec2395efa1",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FoF6YSLw%20-%20Imgur.png?alt=media&token=335fd91e-66a7-47b5-8e22-1a0cad91a10a",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FwKWbzZI%20-%20Imgur.png?alt=media&token=317499f1-fc7f-40f0-9bae-d84a71743764",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2Fjaj4W5f%20-%20Imgur.png?alt=media&token=64055e43-2e36-461c-9a8d-4da813061fc9",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FHi5s1W4%20-%20Imgur.png?alt=media&token=2d15a6d1-49e8-4a55-a98d-2f9e8dbf2db2",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FB73OdMc%20-%20Imgur.png?alt=media&token=6d11ec73-ed8a-4ee2-a842-eb23a97839c0",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/upper-images%2FWd3UnBU%20-%20Imgur.png?alt=media&token=5bc94c3a-0570-48de-aecb-63ccc8defd99",
];

export const additional_images = [
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2FroRdV0u%20-%20Imgur.png?alt=media&token=2d92ac6c-9a5c-4f84-a1e3-cb1a84ee3141",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2Fk3dRTuq%20-%20Imgur.png?alt=media&token=a4ad89fd-3a9b-412f-b634-c91c31041b64",
  // "https://imgur.com/SXBLVcl.png",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2F4BUcwEt%20-%20Imgur.png?alt=media&token=0ba38f77-715d-4928-8df6-39a59d6edfbe",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2Fsfry1EV%20-%20Imgur.png?alt=media&token=2a76074e-42bb-4a67-be3c-073129e9b86f",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2FE60SGuS%20-%20Imgur.png?alt=media&token=901c342c-7a59-4424-83d8-84c92df776a1",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2Fz89faNM%20-%20Imgur.png?alt=media&token=86a44f61-18ca-42dd-a57d-0947c0c79046",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2FaiD2kCs%20-%20Imgur.png?alt=media&token=189a2085-4c87-45c4-8e9b-a4b8a8eded66",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2Fv8aBGP8%20-%20Imgur.png?alt=media&token=852da74d-11d2-4b30-a7d6-9fde15ff582a",
  "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fsliding-images%2Fchase-bank-ss.png?alt=media&token=263e070e-8469-4525-aeca-889c35baea47",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2FVabV2dM%20-%20Imgur.png?alt=media&token=ddc4929f-8f62-49e8-857d-9e746f81efdc",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2FLUd6TIU%20-%20Imgur.png?alt=media&token=c70dd484-f17c-4861-8551-f268bc628eb1",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2FUYr4xDW%20-%20Imgur.png?alt=media&token=ba98a9d0-0d55-44d1-9856-e237b2308ef9",
  "https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/main-images%2FRgMaxEy%20-%20Imgur.png?alt=media&token=5597e48c-2f8a-4cee-9bd5-2180c3d82680",
];


export const initial_img = "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fsliding-images%2Finitial-img%2Finitial_img.png?alt=media&token=a95c7599-40c9-41ab-af15-4b2163887df4";