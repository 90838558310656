import React from 'react';
import './ReviewBagPage.css'; // Make sure to create and link the corresponding CSS file

function ReviewBagPage() {
  return (

    <div className="main-container">
    <div className="left-side">
     <center><div className='device-image'>Device Image will be here</div></center> 
     <div className='view-finance-button'>View Finance Option</div>
    </div>
    <div className="review-bag-container">
    <div className="review-bag-container">
      <h1>Review Your Bag</h1>
      <p className="item-count">1 item</p>
      
      <div className="product-details">
        <h2>Smartphone</h2>
        <p className="product-description">8-inch, Black</p>
        <div className="quantity-price">
          <span>QTY: 1</span>
          <span className="price">$350.00</span>
        </div>
        <div className="data-plan">
          <h3>Selected Data Plan: 5GB</h3>
          <p className="price">$15.00</p>
        </div>
      </div>

      <div className="cost-summary">
        <p><strong>Subtotal</strong><span>$365.00</span></p>
        <p><strong>Tax:</strong><span>$2.10</span></p>
        <p><strong>Shipping</strong><span>Free</span></p>
        <p className="total"><strong>Total</strong><span>$163.50</span></p>
      </div>

      <button className="pay-now-button">Pay Now</button>
    </div>
    </div>
  </div>
    
  );
}

export default ReviewBagPage;
